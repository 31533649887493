import {useMediaQuery, useTheme} from "@material-ui/core";

const useWidth = () => {
	const theme = useTheme();
	const keys = [...theme.breakpoints.keys].reverse();
	return (
		keys.reduce((output, key) => {
			const matches = useMediaQuery(theme.breakpoints.up(key));
			return !output && matches ? key : output;
		}, null) || 'xs'
	);
}

export default useWidth;