import React, {useEffect, useState} from "react";
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {Backdrop, CircularProgress, makeStyles} from "@material-ui/core";
import ModalVideo from 'react-modal-video'

import firebase from "./Api/firebase.api.js";
import * as UserModel from "./Model/user.model";
import * as ShoppingCartModel from "./Model/shopping-cart.model";

import BackdropContext from "./Context/backdrop.context";
import UserContext from "./Context/user.context";

import PlatformNavBar from "./Components/NavBar/PlatformNavBar";
import RouteComponent from "./RouteComponent";

import "./App.css";

const useStyle = makeStyles((theme) => ({
    root: {
        backgroundColor : theme.palette.primary.background,
        color : theme.palette.text.light
    }
}))

function App() {

    const PENDING_USER_OBJECT = {isConnected : true, isPending : true}
    const DISCONNECTED_USER_OBJECT = {isConnected : false}

    const [user, setUser] = useState(PENDING_USER_OBJECT);
    const [backdrop, setBackdrop] = useState(false)

    const history = useHistory();
    const classes = useStyle()

    const location = useLocation();
    
    const handleUserUpdate = (userData) => {
    	//console.log("User update : ", userData )
		setUser({...userData, isConnected: true})
	}

    useEffect(()=>{

        const unsubscribe = firebase.auth().onAuthStateChanged((userRecord)=>{
            //console.log("[Firebase] USER STATE CHANGE !", userRecord);

            if(userRecord === null){
                setUser(DISCONNECTED_USER_OBJECT)
                return;
            }

            UserModel
                .getFromEmail(userRecord.email)
                .then((user) => {
					ShoppingCartModel.merge_shopping_cart(user).then(() => {
						setUser({...user, isConnected: true})
						UserModel.setCallbackUpdate(handleUserUpdate, {...user, isConnected: true})
					})
				})
                .catch(async  (errorMessage) => {
                    //console.log("Error : ", errorMessage)
                    await firebase.auth().signOut();
                    history.push('/accueil')
                });
        }); //subscribe event

        return () => { //unsubscribe when component unmount
            unsubscribe();
        };
    }, []);

    if (location.pathname === "/")
        return <Redirect to="/accueil" />;

    return (
        <div className={classes.root}>
            <BackdropContext.Provider value={{setBackdrop: setBackdrop}}>
                <UserContext.Provider value={user}>
            
                    <PlatformNavBar>
                        <RouteComponent/>
                    </PlatformNavBar>

                    <Backdrop style={{zIndex: 100}} open={backdrop}>
                        <CircularProgress color="secondary" />
                    </Backdrop>

                </UserContext.Provider>
            </BackdropContext.Provider>
        </div>
    );
}

export default App;
