import {isWidthUp} from "@material-ui/core/withWidth";
import {Button, Grid, makeStyles} from "@material-ui/core";
import InputTextField from "../Components/InputTextField";
import React, {useState} from "react";
import firebase from "firebase";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
	formGrid: {
		marginTop: "10vh",
		textAlign: "center",
		"& p" : {
			textAlign : "center"
		}
	}
}));

const ForgotPasswordPage = () => {
	
	const [email, setEmail] = useState("")
	const [sendedMessage, setSendedMessage] = useState("")
	const [error, setError] = useState(null)
	
	const classes = useStyles();
	const history = useHistory();
	
	const handleSendMail = () => {
		firebase.auth().sendPasswordResetEmail(email)
			.then(() => {
				setSendedMessage("Un mail vient de vous être envoyé pour réinitialiser votre mot de passe.")
				setTimeout(() => {
					history.push("/accueil")
				}, 4000)
			})
			.catch((e) => setError(e.message))
	}
	return (
		<Grid
			container
			justify="center"
			spacing={3}
			direction={"column"}
			className={classes.formGrid}
		>
			<p>{sendedMessage}</p>
			<Grid item>
				<InputTextField
					style={{
						width: "60%"
					}}
					handleChange={setEmail}
					type="email"
					placeholder="Votre adresse email"
					value={email}
				/>
			</Grid>
			
			<Grid item>
				<Button
					color="secondary"
					variant="contained"
					disabled={!email || email === ""}
					style={{
						width: "60%",
						borderRadius: "1rem"
					}}
					onClick={handleSendMail}
				>
					<b>Recevoir un mail de réinitialisation</b>
				</Button>
			</Grid>
			
			<Grid item>
				{error && (<p>{error}</p>)}
			</Grid>
		</Grid>
	)
}

export default ForgotPasswordPage;