import firebase from "../Api/firebase.api";

const get = (postId) => (
	firebase.firestore()
		.collection("Blog_Articles")
		.doc(postId)
		.get()
		.then(doc => doc.data())
)

const getAll = () => (
	firebase.firestore()
		.collection("Blog_Articles")
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)

const getAllOrderByRecent = () => (
	firebase.firestore()
		.collection("Blog_Articles")
		.orderBy("creationTime", "asc")
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)

const get4OrderByRecent = () => (
	firebase.firestore()
		.collection("Blog_Articles")
		.orderBy("creationTime", "asc")
		.limit(4)
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)


const get2OrderByRecent = () => (
	firebase.firestore()
		.collection("Blog_Articles")
		.orderBy("creationTime", "asc")
		.limit(2)
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)

export {
	get,
	getAll,
	getAllOrderByRecent,
	get4OrderByRecent,
	get2OrderByRecent
}
//"Products"