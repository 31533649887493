import React, {useEffect, useState} from 'react'

import {makeStyles} from "@material-ui/core/styles";

export default function SimpleMarkdownViewer({value, imageFiles}){
	
	const [valueMarkdown, setValueMarkdown] = useState(value ? value : "");
	
	const useStyles = makeStyles((theme) => ({
		root : {
			height : "calc(100% - 30px)",
			width : "calc(100% - 30px)",
			padding : "15px",
			overflow : "auto"
		},
		markdownImageContainer : {
			width : "100%",
			height : "150px",
			padding : "0px",
			backgroundPosition : "center",
			backgroundSize : "contain",
			backgroundRepeat : "no-repeat"
		},
		markdownImage : {
			maxWidth : "50%",
			height : "100%"
		},
		markdownText : {
			color : theme.palette.text.light,
			"& a" : {
				color : theme.palette.secondary.main
			}
		},
		mardownTitle : {
			color : theme.palette.text.light,
			fontWeight : "500",
			width : "100%",
			textAlign : "center"
		}
	}));
	const classes = useStyles();
	
	useEffect(() => {
		setValueMarkdown(value)
	}, [value])
	
	return(
		<div className={classes.root}>
			{
				valueMarkdown !== null && valueMarkdown !== undefined && valueMarkdown.split("\n").map((ligne, index) => {
					if(ligne.slice(0,2) === "# "){
						return (
							<h2 key={index} className={classes.mardownTitle}>
								{ligne.slice(2)}
							</h2>
						)
					}else if(ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}"){
						var numImage = parseInt(ligne.slice(2).slice(0, -2).split(":")[1])
						if(imageFiles.length >= numImage){
							return (
								<div className={classes.markdownImageContainer} style={{backgroundImage : "url(" + imageFiles[numImage] + ")"}}/>
							)
						}else{
							return(<></>)
						}
						
					}else if(ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"){
						var url = ligne.slice(2).slice(0, -2)
						if(url.slice(0,4) !== "http"){
							//Construction de l'url
							url = "http://" + url
						}
						return (
							<div className={classes.markdownImageContainer} style={{backgroundImage : "url(" + url + ")"}}/>
						)
						
					}else{

						ligne = ligne.replace(/<[^>]*>?/gm, '')

						var urls = ligne.match(/\bhttps?:\/\/\S+/gi);
						if(urls){
							var ligneAvecLienCliquable = ligne
							urls.forEach((url) => {
								ligneAvecLienCliquable = ligneAvecLienCliquable.replace(url, `<a target="_blank" href="${url}">${url}</a>`)
							})
							ligne = ligneAvecLienCliquable
						}

						return (
							<p className={classes.markdownText} dangerouslySetInnerHTML={{ __html: ligne }}/>
						)
					}
				})
			}
		</div>
	)
}
