import {useContext, useEffect, useState} from "react";
import firebase from "firebase";
import Layout from "../../Components/Layout";
import {Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles} from "@material-ui/core";
import Button from "../../Components/Button";
import BackdropContext from "../../Context/backdrop.context";
import {useHistory} from "react-router-dom";
import {CancelRounded, DeleteForeverRounded, RestoreFromTrashRounded} from "@material-ui/icons";


const CoachsPage = () => {
	
	const {setBackdrop} = useContext(BackdropContext)
	const history = useHistory();
	
	const [coachsData, setCoachsData] = useState([])
	const [selectedCoach, setSelectedCoach] = useState(null)
	const [payAmount, setPayAmount] = useState(null)
	
	const [deleteCounter, setDeleteCounter] = useState(0)
	
	useEffect(() => {
		const prepare = async () => {
			var coachsDataTmp = await Promise.all(
				(await firebase.firestore().collection("Users").where("type", "==", "coach").get()).docs.sort((a, b) => {
					const aDate = a.data().creationTime.toDate()
					const bDate = b.data().creationTime.toDate()
					if(aDate < bDate){
						return 1
					}else{
						return -1
					}
				}).map(async (doc) => {
					var coachsDocsData = doc.data()
					
					var programs = (await firebase.firestore().collection("Programmes").where("coachUserId", "==", coachsDocsData.userId).get()).docs.map(doc => doc.data())
					var nbProgram = programs.length
					
					var posts = (await firebase.firestore().collection("Blog_Articles").where("coachUserId", "==", coachsDocsData.userId).get()).docs.map(doc => doc.data())
					var nbPost = posts.length
					
					return {...coachsDocsData, programs : programs, nbProgram : nbProgram, posts : posts, nbPost : nbPost}
				})
			)

			setCoachsData(coachsDataTmp)
		}
		prepare()
	}, [])
	
	const useStyle = makeStyles((theme) => ({
		rootTab : {
			background : theme.palette.primary.brighter,
			width : "100%",
			borderRadius : "15px",
			overflow : "hidden"
		},
		tabHeader : {
			fontWeight : 600,
		},
		tabRow : {
			width : "calc(100% - 30px)",
			backdropFilter : "brightness(150%)",
			padding : "15px",
			margin : "2px 0px"
		},
		manageButton : {
			padding : "15px"
		},
		paramLine : {
			display : "flex",
			flexWrap : "wrap",
			columnGap : "15px"
		},
		listContainer : {
			minHeight : "100px",
			width : "100%",
			display : "flex",
			flexWrap : "wrap",
			justifyContent : "space-evenly",
			alignItems : "center",
			"& > div" : {
				height : "100%",
				background : theme.palette.secondary.main,
				padding : "15px",
				borderRadius : "8px",
				cursor : "pointer",
				display : "flex",
				position : "relative",
				overflow : "hidden"
			}
		},
		programButton : {
			paddingRight : "30%",
			textAlign : "center"
		},
		deleteButton : {
			background : theme.palette.danger.main,
			height : "100%",
			width : "25%",
			position : "absolute",
			right : "0px",
			top : "0px",
			color : "white",
			display : "flex",
			alignItems : "center",
			justifyContent : "center"
		}
	}))
	const classes = useStyle()
	
	const handlePay = async () => {
		setBackdrop(true)
		
		try {
			var newAmount = parseFloat(selectedCoach.balance) - payAmount
			await firebase.firestore().collection("Users").doc(selectedCoach.userId).update({balance : parseFloat(newAmount.toFixed(2))})
			document.location.reload()
		}catch(e){
		
		}finally {
			setBackdrop(false)
		}
		
	}
	
	const handleChangeAccess = (checked, type) => {
		var actualAccess = selectedCoach.allowAccess
		var newAccess = {...actualAccess, [type] : checked}
		
		setSelectedCoach({...selectedCoach, allowAccess : newAccess})
		firebase.firestore().collection("Users").doc(selectedCoach.userId).update({allowAccess : newAccess})
	}
	
	const handleDeleteProgram = async (id) => {
		if (confirm('Etes vous sur de vouloir supprimer ce programme ?')) {
			await firebase.firestore().collection("Programmes").doc(id).delete()
			document.location.reload()
		}
	}
	
	const handleDeletePost = async (id) => {
		if (confirm('Etes vous sur de vouloir supprimer ce programme ?')) {
			await firebase.firestore().collection("Blog_Articles").doc(id).delete()
			document.location.reload()
		}
	}
	
	return (
		<Layout>
			<Grid container className={classes.rootTab}>
				<Grid item xs={12} className={classes.tabRow + " " + classes.tabHeader}>
					<Grid container>
						<Grid item xs={3}>Nom</Grid>
						<Grid item xs={3}>Nombre programmes</Grid>
						<Grid item xs={3}>Nombre articles</Grid>
						<Grid item xs={2}>Cagnotte</Grid>
						<Grid item xs={1}/>
					</Grid>
				</Grid>
				{
					coachsData.map((coach, index) => (
						<Grid item xs={12} className={classes.tabRow} key={index}>
							<Grid container>
								<Grid item xs={3}>{coach.fullname}</Grid>
								<Grid item xs={3}>{coach.nbProgram}</Grid>
								<Grid item xs={3}>{coach.nbPost}</Grid>
								<Grid item xs={2}>{coach.balance && parseFloat(coach.balance).toFixed(2)}€</Grid>
								<Grid item xs={1}><Button className={classes.manageButton} onClick={() => setSelectedCoach(coach)}>Gérer</Button></Grid>
							</Grid>
						</Grid>
					))
				}
			</Grid>
			<Dialog open={selectedCoach !== null} fullScreen>
				{selectedCoach && (
					<>
						<DialogTitle>
							{selectedCoach.fullname}
						</DialogTitle>
						<DialogContent>
							<Grid container>
								<Grid xs={6}>
									<div className={classes.paramLine}>
										<p>Accès articles :</p>
										<Checkbox checked={selectedCoach.allowAccess.blog} onChange={(e) => handleChangeAccess(e.target.checked, "blog")}/>
									</div>
									<div className={classes.paramLine}>
										<p>Accès programmes :</p>
										<Checkbox checked={selectedCoach.allowAccess.programmes} onChange={(e) => handleChangeAccess(e.target.checked, "programmes")}/>
									</div>
									<div className={classes.paramLine}>
										<p>Débiter :</p>
										<input
											value={payAmount}
											type="number"
											onChange={(e) => {
												let amount = parseFloat(e.target.value)
												if(amount < 0){
													amount = 0
												}else if(amount > parseFloat(selectedCoach.balance)){
													amount = parseFloat(selectedCoach.balance)
												}
												setPayAmount(amount)
											}}
										/>
										<p>€</p>
										<Button className={classes.manageButton} onClick={handlePay}>Valider</Button>
									</div>
								</Grid>
								<Grid xs={6}>
									<b>Email : </b>{selectedCoach.email}<br/>
									<b>SIRET : </b>{selectedCoach.siret}<br/>
									<b>Adresse : </b><br/>
									{selectedCoach.location.address1}<br/>
									{selectedCoach.location.address2}<br/>
									{selectedCoach.location.postalCode} {selectedCoach.location.city} {selectedCoach.location.country}<br/>
								</Grid>
							</Grid>
							<h3>Ses programmes</h3>
							<div className={classes.listContainer}>
								{
									selectedCoach.programs.map((program, index) => (
										<div key={index}>
											<div className={classes.programButton}>
												{program.name}
											</div>
											<div onClick={() => handleDeleteProgram(program.programId)} className={classes.deleteButton}>
												<DeleteForeverRounded/>
											</div>
										</div>
									))
								}
							</div>
							<h3>Ses articles</h3>
							<div className={classes.listContainer}>
								{
									selectedCoach.posts.map((post, index) => (
										<div key={index}>
											<div className={classes.programButton}>
												{post.title}
											</div>
											<div onClick={() => handleDeletePost(post.blogArticleId)} className={classes.deleteButton}>
												<DeleteForeverRounded/>
											</div>
										</div>
									))
								}
							</div>
						</DialogContent>
					</>
				)}
				<DialogActions>
					<Button className={classes.manageButton} onClick={() => {
						setSelectedCoach(null)
						setPayAmount(0)
					}}>Fermer</Button>
				</DialogActions>
			</Dialog>
		</Layout>
	)
}

export default CoachsPage