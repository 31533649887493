import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
    ShoppingCartRounded,
    CloudDownloadOutlined,
    CollectionsBookmarkOutlined,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import firebase from "../Api/firebase.api";

import Layout from "../Components/Layout";
import SEO from "../Components/SEO";
import Button from "../Components/Button";
import UserContext from "../Context/user.context";

import * as ShoppingCartModel from "../Model/shopping-cart.model";
import BackdropContext from "../Context/backdrop.context";
import StarRatings from "react-star-ratings";
import ProgramsList from "../Components/Programs/ProgramsList";

const CoachDetailPage = () => {
	
	const [programsData, setProgramsData] = useState([]);
	const [coachData, setCoachData] = useState(null);
	const [nbRatings, setNbRatings] = useState(null);
	const [averageRating, setAverageRating] = useState(null);
	
	const [Cta, setCta] = useState(<></>);
	
    const useStyles = makeStyles((theme) => ({
        ProgramDetailPageComponent: {
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& h2": {
                textAlign: "center",
            },
        },
        note: {
            display: "flex",
            color: theme.palette.secondary.main,
            alignItems: "center",
            margin: "5px 0",
            "& p": {
                color: theme.palette.text.light,
                margin: "0 10px",
            },
        },
        image: {
        	backgroundColor : theme.palette.primary.brighter,
			background : 'no-repeat url(' + (coachData !== null ? coachData.profilPictureLink : "https://firebasestorage.googleapis.com/v0/b/mon-programme-2cb2e.appspot.com/o/Users%2Fdefault.png?alt=media&token=1439a315-03c5-450b-9fe3-0613e33964d8") + ')',
			backgroundPosition : "center",
			backgroundSize : "cover",
            width: "170px",
			height : "170px",
			borderRadius : "300px"
        },
        programsList: {
            display: "flex",
        },
        description : {
        	textAlign : "center",
            textOverflow : "clip",
            wordBreak : "break-word",
			margin : "5%",
            "& a" : {
                color : theme.palette.secondary.main
            }
        }
    }));
    const classes = useStyles();

    const user = useContext(UserContext);
    const { setBackdrop } = useContext(BackdropContext);

    const history = useHistory();
    //const {programId} = useParams();

    useEffect(() => {
        const prepare = async () => {
            try {
                let data = (
                    await firebase
                        .firestore()
                        .collection("Users")
                        .doc(history.location.pathname.split("/")[2])
                        .get()
                ).data();

                data.description = data.description.replace(/<[^>]*>?/gm, '')

                var urls = data.description.match(/\bhttps?:\/\/\S+/gi);
                if(urls){
                    var descriptionWithLink = data.description
                    urls.forEach((url) => {
                        descriptionWithLink = descriptionWithLink.replace(url, `<a target="_blank" href="${url}">${url}</a>`)
                    })
                    data.description = descriptionWithLink
                }

                setCoachData(data);

                let data2 = [];
                data2 = (
                    await firebase
                        .firestore()
                        .collection("Programmes")
                        .where("coachUserId", "==", data.userId)
                        .get()
                ).docs.map((doc) => doc.data());
                setProgramsData(data2);

                let totalRatings = 0;
                let nbRatingsLet = 0;
                data2.forEach((programme) => {
                    programme.reviews.forEach((review) => {
                        totalRatings += review.review;
                        nbRatingsLet++;
                    });
                });
                setAverageRating(totalRatings / nbRatingsLet);
                setNbRatings(nbRatingsLet);

                if (data.type === "customer") {
                    throw "User is not a coach";
                }
            } catch (e) {
                //console.log("Err Coach page : ", e);
            }
        };
        prepare();
    }, [history.location.pathname]);

    let stars;
    if (averageRating) {
        stars = (
            <div className={classes.note}>
                <StarRatings
                    rating={averageRating}
                    starRatedColor="yellow"
                    numberOfStars={5}
                    name="rating"
                    starDimension="20px"
                    starSpacing="5px"
                />{" "}
                <br />
                <p>({nbRatings})</p>
            </div>
        );
    }

    let CTA;
    if (coachData && user.userId === coachData.userId) {
		CTA = 
        <Button
            style={{
                padding: "10px 25px",
                borderRadius: "50px",
                maxWidth: "40%",
                width: "fit-content",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
            }}
            onClick={() =>
                history.push(
                    "/parametres"
                )
            }
        >
            <EditIcon style={{ width: "15%", minWidth: "50px" }} />
            Modifier
        </Button>;
    }

	let AjouterUnProgramme;
    if (coachData && user.userId === coachData.userId) {
		AjouterUnProgramme = 
        <Button
            style={{
                padding: "10px 25px",
                borderRadius: "50px",
                maxWidth: "40%",
                width: "fit-content",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "20px",
            }}
            onClick={() =>
                history.push(
                    "/creation-programme"
                )
            }
        >
            <EditIcon style={{ width: "15%", minWidth: "50px" }} />
            Ajouter un programme
        </Button>;
    }

    return (
        <Layout>
            <SEO/>
            <div className={classes.ProgramDetailPageComponent}>
				{CTA}
                <h2>{coachData ? coachData.fullname : null}</h2>
                {stars}
                <div className={classes.image}/>
                <p className={classes.description} dangerouslySetInnerHTML={{ __html: coachData ? coachData.description : "" }}/>

                <h2>Programmes de {coachData ? coachData.fullname : null}</h2>
				{AjouterUnProgramme}
                <div className={classes.programsList}>
                    {programsData.length !== 0 ? (
                        <ProgramsList programsList={programsData} />
                    ) : <p>Pas encore de programme</p>}
                </div>
            </div>
        </Layout>
    );
};

export default CoachDetailPage;
