import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export default function Card(props) {
	const useStyles = makeStyles((theme) => ({
		cardComponent: {
			overflow : "hidden",
			width: "200px",
			height: "200px",
			backgroundColor : theme.palette.primary.brighter,
			backgroundPosition : "center",
			backgroundImage: "url(" + props.image + ")",
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
			borderRadius: "25px",
			position: "relative",
			[theme.breakpoints.down("sm")]: {
				width: "130px",
				height: "130px" },
		}
	}));
	
	const classes = useStyles();
	return (
		<div className={classes.cardComponent + " " + props.className} {...props}>
			{props.children}
		</div>
	);
}
