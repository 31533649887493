import firebase from '../Api/firebase.api.js'
import {generateString} from "../Utils/string.utils";

const auth_code_translate = {
	"auth/invalid-email": "Adresse e-mail invalide",
	"auth/user-disabled": "Utilisateur désactivé",
	"auth/user-not-found": "Aucun utilisateur ne correspond à cette adresse e-mail",
	"auth/wrong-password": "Mot de passe invalide"
}

const DEFAULT_TYPE = "customer";

var callbackUpdate = () => {
	//console.log("User update")
}
var currentUser = null;

const setCallbackUpdate = (callback, user) => {
	callbackUpdate = callback
	currentUser = user
}

const get = (userId) => (
	firebase
		.firestore()
		.collection("Users")
		.doc(userId)
		.get()
		.then(doc => ({...doc.data(), isConnected : true}))
)


const getFromEmail = (email) => (
	firebase
		.firestore()
		.collection("Users")
		.where("email", "==", email)
		.get()
		.then(queries=>queries.docs[0])
		.then(doc => ({...doc.data(), isConnected : true}))
)


const getFromUid = (uid) => (
	firebase
		.firestore()
		.collection("Users")
		.where("uid", "==", uid)
		.get()
		.then(queries=>queries.docs[0])
		.then(doc => ({...doc.data(), isConnected : true}))
)

const update = (userId, data) => (
	firebase
		.firestore()
		.collection("Users")
		.doc(userId)
		.update(data)
		.then(() => {
			callbackUpdate({...currentUser, ...data})
		})
)

const login = (email, password) => (
	firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.catch(error => Promise.reject(auth_code_translate[error.code]))
)

var semaphore = false
const create = async (email, password, data) => {
	
	if(semaphore){
		return
	}else{
		semaphore = true
	}
	
	const userId = `User-${data.fullname.replace(" ", "-")}-${generateString(4)}`;
	//console.log("userId", userId);
	
	const userData = {
		...data,
		profilPictureLink: "https://firebasestorage.googleapis.com/v0/b/mon-programme-2cb2e.appspot.com/o/Users%2Fdefault.png?alt=media&token=1439a315-03c5-450b-9fe3-0613e33964d8",
		userId: userId,
		balance: 0,
		shoppingCart: [],
		allowAccess: {
			blog: false,
			programmes: false
		},
	};
	
	//console.log("UserModel => userData : ", userData)
	
	try {
		return firebase.firestore().collection("Users").doc(userId).set(userData).then(async () => {
			await firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then(async (userCred)=>{
					const uid = userCred.user.uid;
					await firebase.firestore().collection("Users").doc(userId).update({uid : uid})
					await userCred.user.updateProfile({displayName: data.fullname})
					semaphore = false
				})
				.catch(error=> {
					firebase.firestore().collection("Users").doc(userId).delete()
					Promise.reject(auth_code_translate[error.code])
				})
			return userId;
		})
	} catch (error){
		//console.log("error", error)
		return Promise.reject(error)
	}
	
	
}

export {
	get,
	login,
	create,
	update,
	getFromUid,
	getFromEmail,
	setCallbackUpdate
}