import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../Card";

export default function CardPost({title, description, image, ...props}) {
	
    const useStyles = makeStyles((theme) => ({
		footer: {
			backgroundColor: "rgba(255,255,255,0.5)",
			color: theme.palette.text.dark,
			position : "absolute",
			bottom : "0px",
			height : "60%",
			width : "100%",
			"& h6" : {
				fontSize : "20px",
				[theme.breakpoints.down("sm")]: {
					fontSize : "15px"
				},
			},
			"& p" : {
				fontSize : "15px",
				[theme.breakpoints.down("sm")]: {
					fontSize : "10px"
				},
			}
		},
        label: {
            margin: "2%",
            padding: "0% 2%",
        },
    }));
    const classes = useStyles();
    
    return (
        <Card image={image} style={{margin : "10px"}} {...props}>
            <div className={classes.footer}>
				<h6 className={classes.label}>
					{title}
				</h6>
				<p className={classes.label}>
					{description}
				</p>
            </div>
        </Card>
    );
}
