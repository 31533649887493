import React, {useEffect, useState} from 'react'
import {Button, Grid, makeStyles} from "@material-ui/core";
import {isWidthUp} from '@material-ui/core/withWidth';

import * as UserModel from '../../Model/user.model'
import useWidth from "../../Api/width.hook";
import InputTextField from "../InputTextField";
import firebase from "firebase";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
	formGrid: {
		[theme.breakpoints.down('sm')]: {marginTop: "10vh"},
		textAlign: "center",
	},
	title : {
		[theme.breakpoints.down('sm')]: {marginTop: "5vh"},
		[theme.breakpoints.up('sm')]: {
			marginTop: "0rem",
			color: "white",
			textAlign: "center"
		}
	}
}));

const LoginForm = ({handleClose}) => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [disabled, setDisabled] = useState(true)
	
	const [error, setError] = useState(null);

	const classes = useStyles();
	const width = useWidth();
	const history = useHistory();

	useEffect(()=>{
		setDisabled(email === "" || password === "")
	}, [email, password])

	const handleLogin = async () => {
		try{
			await UserModel.login(email, password)
			handleClose && handleClose();
		}catch(e){
			setError(e)
		}
	}

	const handleForgotPassword = () => {
		history.push("/mot-de-passe-oublie")
	}
	
	return(
		<section id="login-form">
			<h3 className={classes.title}>
				Connexion
			</h3>

			<Grid
				container
				justify="center"
				spacing={!isWidthUp('sm', width) ? 6 : 3}
				direction={"column"}
				className={classes.formGrid}
			>
				<Grid item>
					<InputTextField
						style={{
							width: !isWidthUp('sm', width) ? "60vw" : "13vw"
						}}
						handleChange={setEmail}
						type="email"
						placeholder="Votre adresse email"
						value={email}
					/>
				</Grid>

				<Grid item>
					<InputTextField
						style={{
							width: !isWidthUp('sm', width) ? "60vw" : "13vw"
						}}
						handleChange={setPassword}
						type="password"
						placeholder="Votre mot de passe"
						value={password}
					/>
				</Grid>

				<Grid item>
					<Button
						color="secondary"
						variant="contained"
						disabled={disabled}
						style={{
							width: isWidthUp('sm', width) ? "10vw" : "50vw",
							borderRadius: "1rem"
						}}
						onClick={handleLogin}
					>
						<b>Se connecter</b>
					</Button>
				</Grid>
			</Grid>
			
			{error && (<p style={{color : 'red'}}>{error}</p>)}
			<p
				style={{cursor : "pointer", color : "white", marginTop : "15px", textAlign : "center"}}
				onClick={handleForgotPassword}
			>
				Mot de passe oublié ?<br/>
				Cliquez ici
			</p>
		</section>
	)
}

export default LoginForm