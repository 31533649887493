import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import ProgramsList from '../Components/Programs/ProgramsList';
import Layout from "../Components/Layout";
import SEO from "../Components/SEO";
import useWindowDimensions from "../Components/useWindowDimensions";
import PostsList from "../Components/Blog/BlogPostsList";

import {Dialog} from '@material-ui/core'
import ReactPlayer from 'react-player'
import * as ProgramModel from '../Model/program.model'

import * as BlogModel from '../Model/blog.model'
import {useHistory} from "react-router-dom";
import {CancelRounded, PlayCircleOutlineRounded} from "@material-ui/icons";
import CoachesList from "../Components/Coach/CoachesList";
import firebase from "firebase";

export default function Accueil() {

	const history = useHistory()

	const useStyles = makeStyles((theme) => ({
		accueilComponent: {
			height : "100%",
			width : "100%",
			position : "relative",
			"& a": {
				textDecoration: "underline",
			},
			"& p": {
				marginBottom: "-1em",
			},
		},
		image: {
			width: "100%",
		},
		rowText: {
			display:"flex",
			flexWrap:"wrap",
			alignItems:"center",
			justifyContent:"space-between"
		},
		seeAllText : {
			textDecoration : "none",
			cursor : "pointer"
		},
		imageContainer : {
			position : "relative",
			cursor : "pointer"
		},
		playButtonContainer : {
			position : "absolute",
			height : "100%",
			width : "100%",
			left : "0px",
			top : "0px",
			display : "flex",
			justifyContent : "center",
			alignItems : "flex-end"
		},
		playButton : {
			width : "auto",
			height : "40%",
			marginBottom : "9px",
			opacity : 0.9,
		}
	}));
	const classes = useStyles();

	const [programsDisplay, setProgramsDisplay] = useState([])
	const [blogsDipslay, setBlogsDisplay] = useState([])
	const [coachesDipslay, setCoachesDisplay] = useState([])
	const [isOpen, setIsOpen] = useState(false)

	const { height, width } = useWindowDimensions();

	useEffect(async () => {
		if(width<635){
			ProgramModel
				.get2Programs()
				.then(setProgramsDisplay)

			BlogModel
				.get2OrderByRecent()
				.then(setBlogsDisplay)
			firebase.firestore().collection("Users").where("type", "!=", "customer").limit(2).get().then((snapshot) => {
				setCoachesDisplay(snapshot.docs.map(doc => doc.data()))
			})
		}else{
			ProgramModel
				.get4Programs()
				.then(setProgramsDisplay)

			BlogModel
				.get4OrderByRecent()
				.then(setBlogsDisplay)
			firebase.firestore().collection("Users").where("type", "!=", "customer").limit(4).get().then((snapshot) => {
				setCoachesDisplay(snapshot.docs.map(doc => doc.data()))
			})
		}
	}, []);




	return (

		<React.Fragment>
			<Dialog maxWidth={false} open={isOpen} onClose={()=>setIsOpen(false)} aria-labelledby="form-dialog-title" style={{"&.MuiDialog-paper" : {overflow : "visible", background: "transparent"}}}>
				<div style={{position : "absolute", top : "-15px", left : "-15px"}} onClick={()=>setIsOpen(false)}>
					<CancelRounded color={"secondary"}/>
				</div>
				<ReactPlayer playing={true} controls url='https://www.youtube.com/watch?v=xNIH6oB_2NI' style={{overflowY: "hidden"}} width="100%" height="auto"/>
			</Dialog>
			<div className={classes.accueilComponent}>
				<Layout>
					<SEO/>
					<div onClick={()=>setIsOpen(true)} className={classes.imageContainer}>
						<img className={classes.image} src="https://firebasestorage.googleapis.com/v0/b/mon-programme-2cb2e.appspot.com/o/image.png?alt=media&token=ea6882ba-5845-44d3-94a3-cf6175617272" />
						<div className={classes.playButtonContainer}>
							<PlayCircleOutlineRounded className={classes.playButton}/>
						</div>
					</div>
					<div style={{width:"100%"}}>
						<p>Programmes<br/></p>
						<div className={classes.rowText}>
							<h3>Nos meilleurs programmes</h3>
							<a className={classes.seeAllText} onClick={() => history.push("/nos-programmes")}>Voir tout</a>
						</div>
						<ProgramsList programsList={programsDisplay}/>


						<p>Blog<br/></p>
						<div className={classes.rowText}>
							<h3>Nos derniers articles</h3>
							<a className={classes.seeAllText} onClick={() => history.push("/blog")}>Voir tout</a>
						</div>
						<PostsList postsList={blogsDipslay}/>

						<p>Coach<br/></p>
						<div className={classes.rowText}>
							<h3>Nos coachs</h3>
						</div>
						<CoachesList choachesList={coachesDipslay}/>
					</div>
				</Layout>
			</div>
		</React.Fragment>
	);
}
