import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";

import {makeStyles} from "@material-ui/core/styles";

import Layout from "../Components/Layout";
import SEO from "../Components/SEO";

import * as BlogModel from "../Model/blog.model";
import * as UserModel from "../Model/user.model";
import {CircularProgress, Grid} from "@material-ui/core";
import SimpleMarkdownViewer from "../Components/Blog/SimpleMarkdown/SimpleMarkdownViewer";
import UserContext from "../Context/user.context";
import Button from "../Components/Button";

const BlogPostPage = () => {
	
	const user = useContext(UserContext)
	const history = useHistory()
	
	const [post, setPost] = useState(null)
	const [coach, setCoach] = useState(null)
	//console.log(post)
	//console.log(coach)
	
	useEffect(() => {
		const prepare = async () => {
			try{
				BlogModel.get(history.location.pathname.split("/")[2]).then((postData) => {
					setPost(postData)
					UserModel.get(postData.coachUserId).then((userData) => {
						setCoach(userData)
					})
				})
			}catch(e){
				//console.log(e)
				history.push("/blog")
			}
		}
		prepare();
	}, [history.location.pathname])
	
	
	const useStyles = makeStyles((theme) => ({
		gridRoot : {
			width : "100%"
		},
		header : {
			backgroundImage : post ? `url(${post.imageLink})` : "",
			backgroundPosition : "center",
			backgroundSize : "cover",
			backgroundRepeat : "no-repeat",
			position : "relative",
			overflow : "hidden",
			borderRadius : "10px",
			height : "auto",
			minHeight : "300px",
			[theme.breakpoints.down("sm")] : {
				minHeight : "200px",
			},
			"& div" : {
				width : "100%",
				height : "100%",
				background : "rgba(0,0,0,0.33)",
				display : "flex",
				justifyContent : "center",
				alignItems : "flex-end",
				"& h1" : {
					color : theme.palette.text.light,
					height : "fit-content",
					filter : "brightness(140%)"
				}
			}
		},
		footer : {
			fontSize: "90%",
			"& a" : {
				fontWeight : 700,
				cursor : "pointer"
			}
		}
	}));
	const classes = useStyles();
	
	if(post && coach){
		return (
			<Layout>
            	<SEO/>
				<Grid container className={classes.gridRoot}>
					<Grid item xs={12} className={classes.header}>
						<div>
							<h1>{post.title}</h1>
						</div>
					</Grid>
					{
						(user.type !== "customer" && user.userId === post.coachUserId) && (
							<Grid item xs={12}>
								<Button style={{width : "50%", margin : "15px 15px 15px 25%"}} onClick={() => history.push("/creation-article?postId=" + post.blogArticleId)}>Modifier</Button>
							</Grid>
						)
					}
					<Grid item xs={12} className={classes.content}>
						<SimpleMarkdownViewer value={post.markdown.replace(/\\n/g, "\n")}/>
					</Grid>
					<Grid item xs={12} className={classes.footer}>
						<p>Ecrit par <a onClick={() => history.push("/coach/" + post.coachUserId)}>{coach.fullname}</a>, le {(new Date(post.creationTime.seconds * 1000)).toLocaleDateString("fr")}</p>
					</Grid>
				</Grid>
			</Layout>
		);
	}else{
		return (<CircularProgress color="secondary"/>)
	}
}

export default BlogPostPage;