import Layout from "../../Components/Layout";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	ThemeProvider,
	useTheme
} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {DescriptionRounded, InsertPhotoRounded, PictureAsPdfRounded} from "@material-ui/icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Button from "../../Components/Button";
import Selector from "../../Components/Selector";
import CancelIcon from "@material-ui/icons/Cancel";
import {filters} from "../../secret-client.json";
import {generateString} from "../../Utils/string.utils";
import firebase from "firebase";
import UserContext from "../../Context/user.context";
import {useHistory} from "react-router-dom";
import BackdropContext from "../../Context/backdrop.context";
import SimpleMarkdownEditor from "../../Components/Blog/SimpleMarkdown/SimpleMarkdownEditor";
import Dropzone from "../../Components/Form/Dropzone";

var valueSexFilter = filters.sexes
var valueSeanceFilter = filters.seances
var valueNiveauFilter = filters.niveaux
var valueTagFilter = filters.tags
var valueObjectifFilter = filters.objectifs

const CreationArticlePage = () => {
	
	const CATEGORIES = [
		{name : "Style de vie et nutrition", id : "lifestyleEtNutrition"},
		{name : "Santé", id : "sante"},
		{name : "Pratiques sportives", id : "pratiqueSportive"}
	]
	
	const user = useContext(UserContext)
	const history = useHistory();
	const theme = useTheme();
	const {setBackdrop} = useContext(BackdropContext)
	
	const [isModification, setIsModification] = useState(false);
	const [urlParameters, setUrlParameters] = useState({});
	const [dialogOpen, setDialogOpen] = useState(false);
	
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [category, setCategory] = useState(CATEGORIES[0]);
	
	//Markdown
	const [markdown, setMarkdown] = useState("");
	const [tabImages, setTabImages] = useState([]);
	const [tabImagesReaded, setTabImagesReaded] = useState([]);
	const [oldImagesPaths, setOldImagesPaths] = useState([]);
	
	const [imageUrl, setImageUrl] = useState("");
	const [imageFirebasePath, setImageFirebasePath] = useState("");
	const [imageFile, setImageFile] = useState(undefined);

	const useStyles = makeStyles((theme) => ({
		inputContainer : {
			display : "flex",
			flexWrap : "wrap",
			justifyContent : "space-between",
			alignItems : "center",
			columnGap : "15px",
			padding : "15px",
			"& p" : {
				fontWeight : 600
			},
			"& input" : {
				height : "40%",
				borderRadius : "500px",
				padding : "5px 15px",
				"&[type=number]" : {
					textAlign : "right",
					minWidth : "80px"
				},
				"&[type=text]" : {
					minWidth : "250px"
				}
			},
			"& textarea" : {
				height : "250px",
				width : "100%",
				borderRadius : "25px",
				padding : "15px 15px",
				resize : "none"
			}
		},
		saveButton : {
			width : "50%",
			marginLeft : "25%",
			marginTop : "25px",
			padding : "10px 0px",
		},
		deleteButton : {
			width : "50%",
			marginLeft : "25%",
			marginTop : "25px",
			padding : "10px 0px"
		},
	}))
	const classes = useStyles();

	useEffect(() => {
		var urlParameters = {}
		history.location.search.replace("?", "").split("&").forEach(param => {
			let parts = param.split("=")
			urlParameters[parts[0]] = parts[1]
		})
		setUrlParameters(urlParameters)
		//console.log("urlParameters", urlParameters)
		
		if(urlParameters.postId){
			setBackdrop(true)
			firebase.firestore().collection("Blog_Articles").doc(urlParameters.postId).get().then(async doc => {
				if(!doc.exists) {
					//console.log("Le document n'existe pas")
					setBackdrop(false)
					return
				}
				
				var docData = doc.data()
				
				if(docData.coachUserId !== user.userId) {
					history.push("mes-programmes")
				}
				
				setIsModification(true)
				
				setName(docData.title)
				setDescription(docData.description)
				setCategory(CATEGORIES.filter(cat => cat.id === docData.category)[0])
				setMarkdown(docData.markdown)
				setOldImagesPaths(docData.markdownImagesPaths)
				
				setImageUrl(docData.imageLink)
				
				setBackdrop(false)
				
			}).catch(e => {
				//console.log("Error", e)
				setBackdrop(false)
			})
		}
	}, [])
	
	const uploadMarkdownFile = async (file, refStorage) => {
		var refFile = refStorage.child(file.name)
		await refFile.put(file)
		var downloadLink = await refFile.getDownloadURL()
		var path = refFile.fullPath
		return {url : downloadLink, path : path}
	}

	const handleSave = async () => {

		setBackdrop(true)
		try{
			
			const postId = isModification && urlParameters.postId ? urlParameters.postId : `Blog_Articles-${name.replace(/ /g, "-").normalize()}-${generateString(4)}`;
			
			
			let refStorage = firebase.storage().ref();
			let refPostStorage = refStorage.child("Blog_Articles/" + postId + "/")
			let refMarkdownStorage = refStorage.child("Blog_Articles/" + postId + "/Markdown/")
			
			//MARKDOWN
			//On isole les index des images
			let arrayImageMarkdown = markdown
				.split("\n")
				.filter(ligne => (ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}"))
				.map(val => val.slice(2,-2).split(":")[1])
				.filter(val => (val !== "" && val !== null && val !== undefined))
				.map(val => parseInt(val))
			
			//On supprime les doublons
			let uniqueSet = new Set(arrayImageMarkdown);
			arrayImageMarkdown = [...uniqueSet]
			
			//On upload les images et on récupère le chemin qu'on associe à l'index
			let arrayIndexLink = {}
			let paths = oldImagesPaths
			for(let index of arrayImageMarkdown){
				const {url, path} = await uploadMarkdownFile(tabImages[index], refMarkdownStorage)
				paths.push(path)
				arrayIndexLink = {...arrayIndexLink, [index] : url}
			}
			
			//On remplace les occurences de l'image par le lien
			let newValueLines = markdown.split("\n");
			for(let index of arrayImageMarkdown){
				let imageLinesArray = newValueLines
					.map(ligne => (ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}") ? ligne : null) //On filtre les lignes "image" et on met les autres à null
					.map(ligne => ligne !== null && (parseInt(ligne.slice(2,-2).split(":")[1]) === index) ? ligne : null) //On filtre pour n'avoir que les images du bon index et on met les autres à null
				//On parcours le tableau et on remplace les lignes par leur version 'online'
				imageLinesArray.forEach((ligne, index) => {
					if(ligne !== null){
						newValueLines[index] = "[[" + arrayIndexLink[ ligne.slice(2,-2).split(":")[1] ] + "]]"
					}
				})
			}
			let newValue = newValueLines.join("\n");
			
			var data = {
				blogArticleId : postId,
				category : category.id,
				coachUserId: user.userId,
				markdown : newValue,
				title : name,
				description : description,
				creationTime : new Date(),
				markdownImagesPaths : paths
			}
			
			//console.log(data)
			
			if(imageFile){
				let refImageCover = refPostStorage.child(imageFile.name)
				await refImageCover.put(imageFile)
				
				const imageStorageRef = refImageCover.fullPath
				
				const imageLink = await refImageCover.getDownloadURL()
				
				data = {
					...data,
					imageLink : imageLink,
					imageStorageRef : imageStorageRef,
				}
			}
			
			await firebase.firestore().collection("Blog_Articles").doc(postId).set(data, {merge : true})
			
			history.push("/mes-articles")
		}catch(e){
			//console.log("Erreur dans la création du programme : ", e)
		}finally {
			setBackdrop(false)
		}
	}

	const handleDelete = async () => {
		const postId = urlParameters.postId
		
		const doc = await firebase.firestore().collection("Blog_Articles").doc(postId).get()
		let refStorage = firebase.storage().ref();
		
		let refImageFile = refStorage.child(doc.data().imageStorageRef)
		
		await refImageFile.delete()
		
		for(var path of doc.data().markdownImagesPaths){
			let refMakdownImage = refStorage.child(path)
			
			await refMakdownImage.delete()
		}
		
		await firebase.firestore().collection("Blog_Articles").doc(postId).delete()
		
		history.push("/mes-articles")
	}
	
	const handleImageChange = (url, file) => {
		setImageUrl(url);
		setImageFile(file)
	}

	return (
		<Layout column horizontalCenter>
			<h2>{isModification ? "Modification" : "Ajout"} d'un article</h2>
			<Grid container>
				<Grid item xs={12} md={6}>
					<Grid container style={{width : "100%", height : "100%", display : "flex", flexDirection : "column", justifyContent: "space-evenly"}}>
						<Grid item className={classes.inputContainer}>
							<p>Nom : </p>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Grid>
						<Grid item className={classes.inputContainer}>
							<p>Description : </p>
							<input
								type="text"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Grid>
						<Grid item className={classes.inputContainer}>
							<Selector name={"Categorie"} value={category} onChange={setCategory} values={CATEGORIES} renderValue={(value) => value.name}/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6} style={{width : "100%", display : "flex", flexDirection : "column", justifyContent: "space-evenly"}}>
					<Dropzone
						callbackImageChange={handleImageChange}
						startFileUrl={imageUrl}
						startFileName={imageFile ? imageFile.name : null}
						icon={(<InsertPhotoRounded/>)}
						text={"Cliquez pour choisir l'image de votre programme"}
						accept={'image/*'}
					/>
				</Grid>
				<Grid item xs={12} className={classes.inputContainer}>
					<p style={{width : "100%"}}>Contenu : </p>
					<SimpleMarkdownEditor
						height={"800px"}
						value={markdown}
						onChange={setMarkdown}
						tabImages={tabImages}
						setTabImages={setTabImages}
						tabImagesReaded={tabImagesReaded}
						setTabImagesReaded={setTabImagesReaded}
					/>
				
				</Grid>
				<Grid item xs={12}>
					<Button
						className={classes.saveButton}
						onClick={handleSave}
						disabled={!(
							name && name !== "" &&
							description && description !== "" &&
							markdown && markdown !== "" &&
							imageUrl && imageUrl !== ""
						)}
					>
						Sauvegarder
					</Button>
				</Grid>
				{isModification && (
					<Grid item xs={12}>
						<Button
							color={theme.palette.danger.main}
							className={classes.deleteButton}
							onClick={() => setDialogOpen(true)}
						>
							Supprimer le programme
						</Button>
						<Dialog open={dialogOpen}>
							<DialogTitle>Etes vous sûr de vouloir supprimer ce programme ?</DialogTitle>
							<DialogContent>Vous ne pourrez pas revenir en arrière</DialogContent>
							<DialogActions>
								<Button autoFocus onClick={() => setDialogOpen(false)} color="secondary">
									Annuler
								</Button>
								<Button onClick={() => handleDelete()} color="secondary">
									Supprimer
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				)}
			</Grid>
		</Layout>
	)
}

export default CreationArticlePage