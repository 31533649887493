import firebase from "../Api/firebase.api";

const get = (programId) => (
	firebase.firestore()
		.collection("Programmes")
		.doc(programId)
		.get()
		.then(doc => doc.data())
)

const getAll = () => (
	firebase.firestore()
		.collection("Programmes")
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)

const get4Programs= () => (
	firebase.firestore()
		.collection("Programmes")
		.limit(4)
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)

const get2Programs= () => (
	firebase.firestore()
		.collection("Programmes")
		.limit(2)
		.get()
		.then(snap => snap.docs)
		.then(docs => docs.map(doc => doc.data()))
)
export {
	get,
	getAll,
	get4Programs,
	get2Programs
}
//"Products"