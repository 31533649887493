import Layout from "../../Components/Layout";
import {isWidthUp} from "@material-ui/core/withWidth";
import InputTextField from "../../Components/InputTextField";
import React, {useContext, useEffect, useState} from "react";
import {
	CircularProgress, Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	MenuItem,
	Select
} from "@material-ui/core";
import UserContext from "../../Context/user.context";
import Button from "../../Components/Button";
import firebase from "firebase";
import * as UserModel from "../../Model/user.model";
import {CheckCircleRounded, InsertPhotoRounded, LoopRounded} from "@material-ui/icons";
import Dropzone from "../../Components/Form/Dropzone";
import BackdropContext from "../../Context/backdrop.context";

const countries = [
	{"name": "France", "code": "FR", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "United States", "code": "US"},
	{"name": "Afghanistan", "code": "AF"},
	{"name": "Åland Islands", "code": "AX"},
	{"name": "Albania", "code": "AL"},
	{"name": "Algeria", "code": "DZ"},
	{"name": "American Samoa", "code": "AS"},
	{"name": "Andorra", "code": "AD"},
	{"name": "Angola", "code": "AO"},
	{"name": "Anguilla", "code": "AI"},
	{"name": "Antarctica", "code": "AQ"},
	{"name": "Antigua and Barbuda", "code": "AG"},
	{"name": "Argentina", "code": "AR"},
	{"name": "Armenia", "code": "AM"},
	{"name": "Aruba", "code": "AW"},
	{"name": "Australia", "code": "AU"},
	{"name": "Austria", "code": "AT", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Azerbaijan", "code": "AZ"},
	{"name": "Bahamas", "code": "BS"},
	{"name": "Bahrain", "code": "BH"},
	{"name": "Bangladesh", "code": "BD"},
	{"name": "Barbados", "code": "BB"},
	{"name": "Belarus", "code": "BY"},
	{"name": "Belgium", "code": "BE", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Belize", "code": "BZ"},
	{"name": "Benin", "code": "BJ"},
	{"name": "Bermuda", "code": "BM"},
	{"name": "Bhutan", "code": "BT"},
	{"name": "Bolivia", "code": "BO"},
	{"name": "Bosnia and Herzegovina", "code": "BA"},
	{"name": "Botswana", "code": "BW"},
	{"name": "Bouvet Island", "code": "BV"},
	{"name": "Brazil", "code": "BR"},
	{"name": "British Indian Ocean Territory", "code": "IO"},
	{"name": "Brunei Darussalam", "code": "BN"},
	{"name": "Bulgaria", "code": "BG", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Burkina Faso", "code": "BF"},
	{"name": "Burundi", "code": "BI"},
	{"name": "Cambodia", "code": "KH"},
	{"name": "Cameroon", "code": "CM"},
	{"name": "Canada", "code": "CA"},
	{"name": "Cape Verde", "code": "CV"},
	{"name": "Cayman Islands", "code": "KY"},
	{"name": "Central African Republic", "code": "CF"},
	{"name": "Chad", "code": "TD"},
	{"name": "Chile", "code": "CL"},
	{"name": "China", "code": "CN"},
	{"name": "Christmas Island", "code": "CX"},
	{"name": "Cocos (Keeling) Islands", "code": "CC"},
	{"name": "Colombia", "code": "CO"},
	{"name": "Comoros", "code": "KM"},
	{"name": "Congo", "code": "CG"},
	{"name": "Congo, The Democratic Republic of the", "code": "CD"},
	{"name": "Cook Islands", "code": "CK"},
	{"name": "Costa Rica", "code": "CR"},
	{"name": "Cote D'Ivoire", "code": "CI"},
	{"name": "Croatia", "code": "HR", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Cuba", "code": "CU"},
	{"name": "Cyprus", "code": "CY", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Czech Republic", "code": "CZ", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Denmark", "code": "DK", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Djibouti", "code": "DJ"},
	{"name": "Dominica", "code": "DM"},
	{"name": "Dominican Republic", "code": "DO"},
	{"name": "Ecuador", "code": "EC"},
	{"name": "Egypt", "code": "EG"},
	{"name": "El Salvador", "code": "SV"},
	{"name": "Equatorial Guinea", "code": "GQ"},
	{"name": "Eritrea", "code": "ER"},
	{"name": "Estonia", "code": "EE", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Ethiopia", "code": "ET"},
	{"name": "Falkland Islands (Malvinas)", "code": "FK"},
	{"name": "Faroe Islands", "code": "FO"},
	{"name": "Fiji", "code": "FJ"},
	{"name": "Finland", "code": "FI", "isEU" : true, "inclusive" : true, "vatPercentage": 24},
	{"name": "French Guiana", "code": "GF"},
	{"name": "French Polynesia", "code": "PF"},
	{"name": "French Southern Territories", "code": "TF"},
	{"name": "Gabon", "code": "GA"},
	{"name": "Gambia", "code": "GM"},
	{"name": "Georgia", "code": "GE"},
	{"name": "Germany", "code": "DE", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Ghana", "code": "GH"},
	{"name": "Gibraltar", "code": "GI"},
	{"name": "Greece", "code": "GR", "isEU" : true, "inclusive" : true, "vatPercentage": 24},
	{"name": "Greenland", "code": "GL"},
	{"name": "Grenada", "code": "GD"},
	{"name": "Guadeloupe", "code": "GP"},
	{"name": "Guam", "code": "GU"},
	{"name": "Guatemala", "code": "GT"},
	{"name": "Guernsey", "code": "GG"},
	{"name": "Guinea", "code": "GN"},
	{"name": "Guinea-Bissau", "code": "GW"},
	{"name": "Guyana", "code": "GY"},
	{"name": "Haiti", "code": "HT"},
	{"name": "Heard Island and Mcdonald Islands", "code": "HM"},
	{"name": "Holy See (Vatican City State)", "code": "VA"},
	{"name": "Honduras", "code": "HN"},
	{"name": "Hong Kong", "code": "HK"},
	{"name": "Hungary", "code": "HU", "isEU" : true, "inclusive" : true, "vatPercentage": 27},
	{"name": "Iceland", "code": "IS"},
	{"name": "India", "code": "IN"},
	{"name": "Indonesia", "code": "ID"},
	{"name": "Iran, Islamic Republic Of", "code": "IR"},
	{"name": "Iraq", "code": "IQ"},
	{"name": "Ireland", "code": "IE", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Isle of Man", "code": "IM"},
	{"name": "Israel", "code": "IL"},
	{"name": "Italy", "code": "IT", "isEU" : true, "inclusive" : true, "vatPercentage": 22},
	{"name": "Jamaica", "code": "JM"},
	{"name": "Japan", "code": "JP"},
	{"name": "Jersey", "code": "JE"},
	{"name": "Jordan", "code": "JO"},
	{"name": "Kazakhstan", "code": "KZ"},
	{"name": "Kenya", "code": "KE"},
	{"name": "Kiribati", "code": "KI"},
	{"name": "Korea, Democratic People'S Republic of", "code": "KP"},
	{"name": "Korea, Republic of", "code": "KR"},
	{"name": "Kuwait", "code": "KW"},
	{"name": "Kyrgyzstan", "code": "KG"},
	{"name": "Lao People'S Democratic Republic", "code": "LA"},
	{"name": "Latvia", "code": "LV", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Lebanon", "code": "LB"},
	{"name": "Lesotho", "code": "LS"},
	{"name": "Liberia", "code": "LR"},
	{"name": "Libyan Arab Jamahiriya", "code": "LY"},
	{"name": "Liechtenstein", "code": "LI"},
	{"name": "Lithuania", "code": "LT", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Luxembourg", "code": "LU", "isEU" : true, "inclusive" : true, "vatPercentage": 17},
	{"name": "Macao", "code": "MO"},
	{"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
	{"name": "Madagascar", "code": "MG"},
	{"name": "Malawi", "code": "MW"},
	{"name": "Malaysia", "code": "MY"},
	{"name": "Maldives", "code": "MV"},
	{"name": "Mali", "code": "ML"},
	{"name": "Malta", "code": "MT", "isEU" : true, "inclusive" : true, "vatPercentage": 18},
	{"name": "Marshall Islands", "code": "MH"},
	{"name": "Martinique", "code": "MQ"},
	{"name": "Mauritania", "code": "MR"},
	{"name": "Mauritius", "code": "MU"},
	{"name": "Mayotte", "code": "YT"},
	{"name": "Mexico", "code": "MX"},
	{"name": "Micronesia, Federated States of", "code": "FM"},
	{"name": "Moldova, Republic of", "code": "MD"},
	{"name": "Monaco", "code": "MC"},
	{"name": "Mongolia", "code": "MN"},
	{"name": "Montserrat", "code": "MS"},
	{"name": "Morocco", "code": "MA"},
	{"name": "Mozambique", "code": "MZ"},
	{"name": "Myanmar", "code": "MM"},
	{"name": "Namibia", "code": "NA"},
	{"name": "Nauru", "code": "NR"},
	{"name": "Nepal", "code": "NP"},
	{"name": "Netherlands", "code": "NL", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Netherlands Antilles", "code": "AN"},
	{"name": "New Caledonia", "code": "NC"},
	{"name": "New Zealand", "code": "NZ"},
	{"name": "Nicaragua", "code": "NI"},
	{"name": "Niger", "code": "NE"},
	{"name": "Nigeria", "code": "NG"},
	{"name": "Niue", "code": "NU"},
	{"name": "Norfolk Island", "code": "NF"},
	{"name": "Northern Mariana Islands", "code": "MP"},
	{"name": "Norway", "code": "NO"},
	{"name": "Oman", "code": "OM"},
	{"name": "Pakistan", "code": "PK"},
	{"name": "Palau", "code": "PW"},
	{"name": "Palestinian Territory, Occupied", "code": "PS"},
	{"name": "Panama", "code": "PA"},
	{"name": "Papua New Guinea", "code": "PG"},
	{"name": "Paraguay", "code": "PY"},
	{"name": "Peru", "code": "PE"},
	{"name": "Philippines", "code": "PH"},
	{"name": "Pitcairn", "code": "PN"},
	{"name": "Poland", "code": "PL", "isEU" : true, "inclusive" : true, "vatPercentage": 23},
	{"name": "Portugal", "code": "PT", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Puerto Rico", "code": "PR"},
	{"name": "Qatar", "code": "QA"},
	{"name": "Reunion", "code": "RE"},
	{"name": "Romania", "code": "RO"},
	{"name": "Russian Federation", "code": "RU"},
	{"name": "RWANDA", "code": "RW"},
	{"name": "Saint Helena", "code": "SH"},
	{"name": "Saint Kitts and Nevis", "code": "KN"},
	{"name": "Saint Lucia", "code": "LC"},
	{"name": "Saint Pierre and Miquelon", "code": "PM"},
	{"name": "Saint Vincent and the Grenadines", "code": "VC"},
	{"name": "Samoa", "code": "WS"},
	{"name": "San Marino", "code": "SM"},
	{"name": "Sao Tome and Principe", "code": "ST"},
	{"name": "Saudi Arabia", "code": "SA"},
	{"name": "Senegal", "code": "SN"},
	{"name": "Serbia and Montenegro", "code": "CS"},
	{"name": "Seychelles", "code": "SC"},
	{"name": "Sierra Leone", "code": "SL"},
	{"name": "Singapore", "code": "SG"},
	{"name": "Slovakia", "code": "SK", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Slovenia", "code": "SI", "isEU" : true, "inclusive" : true, "vatPercentage": 22},
	{"name": "Solomon Islands", "code": "SB"},
	{"name": "Somalia", "code": "SO"},
	{"name": "South Africa", "code": "ZA"},
	{"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
	{"name": "Spain", "code": "ES", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Sri Lanka", "code": "LK"},
	{"name": "Sudan", "code": "SD"},
	{"name": "Suriname", "code": "SR"},
	{"name": "Svalbard and Jan Mayen", "code": "SJ"},
	{"name": "Swaziland", "code": "SZ"},
	{"name": "Sweden", "code": "SE", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Switzerland", "code": "CH"},
	{"name": "Syrian Arab Republic", "code": "SY"},
	{"name": "Taiwan, Province of China", "code": "TW"},
	{"name": "Tajikistan", "code": "TJ"},
	{"name": "Tanzania, United Republic of", "code": "TZ"},
	{"name": "Thailand", "code": "TH"},
	{"name": "Timor-Leste", "code": "TL"},
	{"name": "Togo", "code": "TG"},
	{"name": "Tokelau", "code": "TK"},
	{"name": "Tonga", "code": "TO"},
	{"name": "Trinidad and Tobago", "code": "TT"},
	{"name": "Tunisia", "code": "TN"},
	{"name": "Turkey", "code": "TR"},
	{"name": "Turkmenistan", "code": "TM"},
	{"name": "Turks and Caicos Islands", "code": "TC"},
	{"name": "Tuvalu", "code": "TV"},
	{"name": "Uganda", "code": "UG"},
	{"name": "Ukraine", "code": "UA"},
	{"name": "United Arab Emirates", "code": "AE"},
	{"name": "United Kingdom", "code": "GB", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "United States Minor Outlying Islands", "code": "UM"},
	{"name": "Uruguay", "code": "UY"},
	{"name": "Uzbekistan", "code": "UZ"},
	{"name": "Vanuatu", "code": "VU"},
	{"name": "Venezuela", "code": "VE"},
	{"name": "Viet Nam", "code": "VN"},
	{"name": "Virgin Islands, British", "code": "VG"},
	{"name": "Virgin Islands, U.S.", "code": "VI"},
	{"name": "Wallis and Futuna", "code": "WF"},
	{"name": "Western Sahara", "code": "EH"},
	{"name": "Yemen", "code": "YE"},
	{"name": "Zambia", "code": "ZM"},
	{"name": "Zimbabwe", "code": "ZW"}
]
const HOME_COUNTRY_CODE = 'FR';

const ParametresPage = () => {
	
	var user = useContext(UserContext)
	
	const useStyle = makeStyles((theme) => ({
		textInput : {
			width : "60%",
			margin : "15px"
		},
		selectInput : {
			width : "60%",
			margin : "15px",
			background : "#d0d0d0",
			borderRadius : "50px",
			textAlign : "center"
		}
	}))
	const classes = useStyle()
	
	return (
		<Layout horizontalCenter column>
			<h3>Vos informations de connexion</h3>
			<UpdateEmail classes={classes} user={user}/>
			<UpdatePassword classes={classes} user={user}/>
			{
				(user && (user.type === "coach" || user.type === "admin")) && (<UpdateCoachProfile user={user}/>)
			}
			<h3>Vos informations personnelles</h3>
			<UpdateLocation classes={classes} user={user}/>
		</Layout>
	)
}

const UpdateEmail = ({classes, user}) => {
	
	const DEFAULT_BUTTON_CONTENT = "Changer"
	const ERROR_BUTTON_CONTENT = "Erreur"
	const SENDED_BUTTON_CONTENT = <CheckCircleRounded/>
	
	const [email, setEmail] = useState("")
	const [buttonOpacity, setButtonOpacity] = useState(1)
	const [buttonContent, setButtonContent] = useState(DEFAULT_BUTTON_CONTENT)
	const [dialogOpen, setDialogOpen] = useState(false)
	
	useEffect(() => {
		setEmail(user.email)
	}, [user])
	
	const useStyle = makeStyles((theme) => ({
		rootUpdateEmail : {
			width : "60%",
			margin : "15px 0px",
			"& div" : {
				display : "flex",
				alignItems : "center",
				justifyContent : "center",
				"& input" : {
					width : "100%",
					margin : "0px 15px 0px 0px"
				}
			}
		},
		button : {
			opacity : buttonOpacity,
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			height : "35px",
			width : "100%",
			[theme.breakpoints.down("sm")] : {
				width : "60%",
				margin : "15px"
			}
		}
	}))
	const localClasses = useStyle()
	
	const handleChangeEmail = () => {
		if(buttonContent === DEFAULT_BUTTON_CONTENT){
			setButtonOpacity(0.5)
			const firebaseUser = firebase.auth().currentUser;
			firebaseUser.updateEmail(email).then(() => {
				UserModel.update(user.userId, {email : email}).then(() => {
					setButtonContent(SENDED_BUTTON_CONTENT)
				})
			}).catch((error) => {
				setButtonContent(ERROR_BUTTON_CONTENT)
				if(error.code === "auth/requires-recent-login"){
					setDialogOpen(true)
				}else{
					//console.log(error)
				}
			}).finally(() => {
				setButtonOpacity(1)
				setTimeout(() => {
					setButtonContent(DEFAULT_BUTTON_CONTENT)
				}, 5000)
			});
		}
	}
	
	const handleDisconnect = () => {
		firebase.auth().signOut()
	}
	
	return(
		<>
			<Grid container className={localClasses.rootUpdateEmail}>
				<Grid item xs={12} md={8}>
					<InputTextField
						className={classes.textInput}
						handleChange={setEmail}
						type="email"
						placeholder="Votre adresse email"
						value={email}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<Button className={localClasses.button} onClick={handleChangeEmail}>
						{buttonContent}
					</Button>
				</Grid>
			</Grid>
			<Dialog open={dialogOpen}>
				<DialogTitle>Attention !</DialogTitle>
				<DialogContent>Cette opération est sensible et nécéssite d'être connecté depuis peu de temps. Veuillez vous déconnecter et vous reconnecter pour changer d'adresse email.</DialogContent>
				<DialogActions>
					<Button onClick={() => setDialogOpen(false)} color="primary">
						Annuler
					</Button>
					<Button onClick={handleDisconnect} color="primary">
						Se déconnecter
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

const UpdatePassword = ({classes, user}) => {
	
	const DEFAULT_BUTTON_TEXT = "Cliquez ici pour changer votre mot de passe"
	const SENDED_BUTTON_TEXT = "Regardez votre boite mail !"
	
	const [buttonText, setButtonText] = useState(DEFAULT_BUTTON_TEXT)
	
	const useStyle = makeStyles((theme) => ({
		rootUpdatePassword : {
			width : "80%",
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
		},
		button : {
			width : "60%",
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			minHeight : "35px",
			padding : "3px 10px"
		}
	}))
	const localClasses = useStyle()
	
	const handleChangePassword = () => {
		firebase.auth().sendPasswordResetEmail(user.email)
			.then(() => {
				setButtonText(SENDED_BUTTON_TEXT)
				setTimeout(() => {
					setButtonText(DEFAULT_BUTTON_TEXT)
				}, 5000)
			})
	}
	
	return(
		<div className={localClasses.rootUpdatePassword}>
			<Button onClick={handleChangePassword} className={localClasses.button}>
				{buttonText}
			</Button>
		</div>
	)
}

const UpdateLocation = ({classes, user}) => {
	
	const DEFAULT_BUTTON_CONTENT = "Sauvegarder"
	const ERROR_BUTTON_CONTENT = "Erreur"
	const SENDED_BUTTON_CONTENT = <CheckCircleRounded/>
	
	const [fullname, setFullname] = useState("")
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")
	const [postalCode, setPostalCode] = useState("")
	const [city, setCity] = useState("")
	const [country, setCountry] = useState("FR")
	const [siret, setSiret] = useState("")
	
	const [buttonOpacity, setButtonOpacity] = useState(1)
	const [buttonContent, setButtonContent] = useState(DEFAULT_BUTTON_CONTENT)
	
	const useStyle = makeStyles((theme) => ({
		button : {
			opacity : buttonOpacity,
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			height : "35px",
			width : "60%",
			margin : "15px"
		}
	}))
	const localClasses = useStyle()
	
	useEffect(() => {
		setFullname(user.fullname)
		setAddress1(user.location.address1)
		setAddress2(user.location.address2)
		setPostalCode(user.location.postalCode)
		setCity(user.location.city)
		setCountry(user.location.country)
		setSiret(user.siret)
	}, [user])
	
	const handleSave = () => {
		if(buttonContent === DEFAULT_BUTTON_CONTENT) {
			setButtonOpacity(0.5)
			UserModel.update(user.userId, {
				fullname: fullname,
				location: {
					address1: address1,
					address2: address2 ? address2 : "",
					postalCode: postalCode,
					city: city,
					country: country
				},
				vat: siret ? siret : ""
			}).then(() => {
				firebase.auth().currentUser.updateProfile({displayName: fullname}).then(() => {
					setButtonContent(SENDED_BUTTON_CONTENT)
				})
			}).catch((e) => {
				//console.log(e)
				setButtonContent(ERROR_BUTTON_CONTENT)
			}).finally(() => {
				setButtonOpacity(1)
				setTimeout(() => {
					setButtonContent(DEFAULT_BUTTON_CONTENT)
				}, 5000)
			});
		}
	}
	
	return (
		<>
			<InputTextField
				className={classes.textInput}
				handleChange={setFullname}
				type="text"
				placeholder="Votre nom complet"
				value={fullname}
			/>
			<InputTextField
				className={classes.textInput}
				handleChange={setAddress1}
				type="text"
				placeholder="Votre adresse ligne 1"
				value={address1}
			/>
			<InputTextField
				className={classes.textInput}
				handleChange={setAddress2}
				type="text"
				placeholder="Votre adresse ligne 2"
				value={address2}
			/>
			<InputTextField
				className={classes.textInput}
				handleChange={setPostalCode}
				type="text"
				placeholder="Votre code postal"
				value={postalCode}
			/>
			<InputTextField
				className={classes.textInput}
				handleChange={setCity}
				type="text"
				placeholder="Votre ville"
				value={city}
			/>
			<Select
				labelId="demo-simple-select-filled-label"
				id="demo-simple-select-filled"
				className={classes.selectInput}
				value={country}
				placeholder="Votre pays"
				onChange={(e) => setCountry(e.target.value)}
			>
				{
					countries.map((countryData, index) => (
						<MenuItem value={countryData.code} key={index}>{countryData.name}</MenuItem>
					))
				}
			</Select>
			<InputTextField
				className={classes.textInput}
				handleChange={setSiret}
				type="text"
				placeholder="Votre numéro SIRET"
				value={siret}
			/>
			<Button
				className={localClasses.button}
				onClick={handleSave}
				disabled={ country === "" || city === "" || postalCode === "" || address1 === "" || fullname === "" }
			>
				{buttonContent}
			</Button>
		</>
	)
}

const UpdateCoachProfile = ({user}) => {
	
	const DEFAULT_BUTTON_CONTENT = "Sauvegarder"
	const ERROR_BUTTON_CONTENT = "Erreur"
	const SENDED_BUTTON_CONTENT = <CheckCircleRounded/>
	
	const [buttonOpacity, setButtonOpacity] = useState(1)
	const [buttonContent, setButtonContent] = useState(DEFAULT_BUTTON_CONTENT)
	
	const [description, setDescription] = useState("")
	
	const [profilPictureUrl, setProfilPictureUrl] = useState("");
	const [profilPictureFirebasePath, setProfilPictureFirebasePath] = useState("");
	const [profilPictureFile, setProfilPictureFile] = useState(undefined);
	
	const {setBackdrop} = useContext(BackdropContext)
	
	useEffect(() => {
		setProfilPictureFirebasePath(user.profilPictureStorageRef)
		setProfilPictureUrl(user.profilPictureLink)
		setDescription(user.description)
	}, [user])
	
	const handleImageChange = (url, file) => {
		setProfilPictureUrl(url);
		setProfilPictureFile(file)
	}
	
	const handleSave = async () => {
		
		if(buttonContent === DEFAULT_BUTTON_CONTENT) {
			setButtonOpacity(0.5)
			try {
				
				var data = {
					description: description ?? ''
				}
				
				if(profilPictureFile) {
					let refStorage = firebase.storage().ref();
					
					if(profilPictureFirebasePath) {
						await refStorage.child(profilPictureFirebasePath).delete()
						setProfilPictureFirebasePath("")
					}
					
					let refProfilPicture = refStorage.child("Users/" + user.userId + "/" + profilPictureFile.name)
					await refProfilPicture.put(profilPictureFile)
					
					const profilPictureStorageRef = refProfilPicture.fullPath
					setProfilPictureFirebasePath(profilPictureStorageRef)
					
					const profilPictureLink = await refProfilPicture.getDownloadURL()
					
					data = {
						...data,
						profilPictureLink: profilPictureLink,
						profilPictureStorageRef: profilPictureStorageRef
					}
				}
				
				await firebase.firestore().collection("Users").doc(user.userId).update(data)
				
				setButtonContent(SENDED_BUTTON_CONTENT)
				
			} catch(e) {
				//console.log(e)
				setButtonContent(ERROR_BUTTON_CONTENT)
			} finally {
				setButtonOpacity(1)
				setTimeout(() => {
					setButtonContent(DEFAULT_BUTTON_CONTENT)
				}, 5000)
			}
		}
	}
	
	return (
		<>
			<h3>Votre profil de coach</h3>
			<Grid container>
				<Grid xs={6}>
					<p style={{width : "100%", marginLeft : "10px"}}>Description : </p>
					<textarea
						style={{height : "calc(100% - 70px - 16px)", width : "calc(100% - 20px - 16px)", margin : "0px 10px 20px 10px", borderRadius : "15px", padding : "8px"}}
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</Grid>
				<Grid xs={6}>
					<Dropzone
						callbackImageChange={handleImageChange}
						startFileUrl={profilPictureUrl}
						startFileName={profilPictureFile ? profilPictureFile.name : null}
						icon={(<InsertPhotoRounded/>)}
						text={"Cliquez pour choisir votre photo de profil"}
						accept={'image/*'}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={handleSave} style={{width : "60%", padding : "10px", marginLeft : "20%"}}>{buttonContent}</Button>
				</Grid>
			</Grid>
		</>
	)
}

export default ParametresPage;