import Cookies from 'js-cookie'
import * as ProgramModel from '../Model/program.model'
import * as ProductModel from '../Model/product.model'
import * as UserModel from '../Model/user.model'

const NB_MAX_SHOPPING_CART_OBJECT = 999;
const TYPES = {
	programme : "programme",
	product : "product"
}

const get_content = async (contextUserObject) => {
	//Si l'utilisateur est connecté on se base sur le panier de son compte
	if(contextUserObject && !contextUserObject.isPending && contextUserObject.isConnected)
		return contextUserObject.shoppingCart ?? []

	//Si l'utilisateur n'est pas connecté on se base sur le panier en cookie
	return Cookies.get("shopping_cart") ? JSON.parse(Cookies.get("shopping_cart")) : []
}

const get_nb_items = async (contextUserObject) => {
	var content = get_content(contextUserObject)
	return (await content).length
}

const get_expanded_content = async (contextUserObject) => {
	let goods = await get_content(contextUserObject)

	return await Promise.all(
		goods.map(async (good) => {
			var object = good.type === "programme" ? await ProgramModel.get(good.id) : await ProductModel.get(good.id);
			return {...good, object: object};
		})
	)
}

const merge_shopping_cart = async (userData) => {
	let userPrograms = userData.programs ?? []
	let connectedCart = userData.shoppingCart ?? []

	let cookie_cart = Cookies.get("shopping_cart")  ? JSON.parse(Cookies.get("shopping_cart") ) : []
	
	let final_cart = [...cookie_cart]
	for(var good of connectedCart){
		var alreadyInCart = false
		
		for(var inCartGood of cookie_cart){
			if(inCartGood.id === good.id){
				alreadyInCart = true;
				break;
			}
		}
		
		if(!alreadyInCart){
			final_cart.push(good)
		}
	}
	final_cart.filter(good => userPrograms.indexOf(good.id) === -1)
	
	Cookies.set("shopping_cart", [], { expires: 7 })
}

const save = async (contextUserObject, shopping_cart) => {
	if(contextUserObject && !contextUserObject.isPending && contextUserObject.isConnected)
		return await UserModel.update(contextUserObject.userId, {shoppingCart: shopping_cart})

	Cookies.set("shopping_cart", shopping_cart, { expires: 7 })
}

const add = async (contextUserObject, contentId, type, quantity = 1) => {
	let shopping_cart = await get_content(contextUserObject);

	const shoppingCartObject = shopping_cart.find(shoppingElement=>shoppingElement.id === contentId);

	if(!shoppingCartObject) {
		//console.log("Ajout au panier")
		shopping_cart = [...shopping_cart, {
			id : contentId,
			type : type,
			quantity : Math.min(quantity, NB_MAX_SHOPPING_CART_OBJECT)
		}]
		//console.log(shopping_cart)
	} else {
		if(type !== TYPES.programme){
			//console.log("Ajout de quantité")
			shoppingCartObject.quantity = Math.min((shoppingCartObject.quantity + quantity), NB_MAX_SHOPPING_CART_OBJECT);
		}
	}

	await save(contextUserObject, shopping_cart)
}

const remove = async (contextUserObject, contentId, quantity = 1) => {
	let shopping_cart = await get_content(contextUserObject);
	//console.log(shopping_cart)

	const shoppingCartObject = shopping_cart.find(shoppingElement=>shoppingElement.id === contentId);
	//console.log(shoppingCartObject)

	if(shoppingCartObject.quantity > quantity) //On enlève la quantité voulu et on supprime l'objet si quantité <= 0
		shoppingCartObject.quantity -= quantity;
	else
		shopping_cart = shopping_cart.filter(shoppingElement=>shoppingElement !== shoppingCartObject)
	
	//console.log(shopping_cart)

	await save(contextUserObject, shopping_cart);
}

export {
	get_content,
	get_expanded_content,
	get_nb_items,
	merge_shopping_cart,
	add,
	remove,
	TYPES
}