import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../Card";

export default function CardProgram(props) {
    const useStyles = makeStyles((theme) => ({
        header: {
            display: "flex",
            justifyContent: "flex-start",
            paddingLeft : "5px",
            paddingTop : "5px",
            maxWidth : "100%",
            overflow : "auto",
            "-ms-overflow-style": "none",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar" : {
                display: "none"
            }
        },
        label: {
            color: "white",
            backgroundColor: theme.palette.secondary.main,
            margin: "2%",
            padding: "3% 10%",
            borderRadius: "25px",
        },
    }));

    const classes = useStyles();
    return (
        <Card {...props}>
            <div className={classes.header}>
                {
                    props.sex && (
                        <div className={classes.label}>{props.sex}</div>
                    )
                }
                {
                    props.objective && (
                        <div className={classes.label}>{props.objective === "Performance" ? "Perf" : props.objective}</div>
                    )
                }

            </div>
        </Card>
    );
}
