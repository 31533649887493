import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CardProgram from "./CardProgram";
import StarIcon from "@material-ui/icons/Star";
import { PinDropSharp } from "@material-ui/icons";
import StarRatings from 'react-star-ratings';

const useStyles = makeStyles((theme) => ({
    programComponent: {
        margin: "10px",
        [theme.breakpoints.down("sm")]: { 
            width: "130px",  },
    },
    title: {
        color: theme.palette.text.light,
        margin: "5px 0",
    },
    price: {
        color: theme.palette.secondary.main,
        margin: "5px 0",
    },
	note: {
		display: "flex",
		color: theme.palette.secondary.main,
		alignItems: "center",
		margin: "5px 0",
		"& p": {
			color: theme.palette.text.light,
			margin: "0 10px",
		},
	},
    star:{
        [theme.breakpoints.down("sm")]: { 
            width: "0.8em",  },
    }
}));

export default function Program({sex, objective = "",image,nbNote=3,title,price, onClick, sumNote}) {
    const classes = useStyles();


    let rating;
    if(nbNote !== 0){
        rating = 
        <div className={classes.note}>
            <StarRatings
                rating={sumNote ? sumNote/nbNote : 5}
                starRatedColor="yellow"
                numberOfStars={5}
                name='rating'
                starDimension="15px"
                starSpacing="2px"
            />
            <p>({nbNote})</p>
        </div>
    }

    return (
        <div className={classes.programComponent} onClick ={onClick}>
            <CardProgram
                sex={sex}
                objective={objective}
                image={image}
            />
            {rating}
            <div className={classes.title}>{title}</div>
            <div className={classes.price}>{price}</div>
        </div>
    );
}
