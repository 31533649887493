import React from "react";
import { Helmet } from "react-helmet";

function SEO({ description, lang, title }) {

  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      <meta name={"description"} content={description} />
      <meta property="og:type" content="article" />
    </Helmet>
  )
};

SEO.defaultProps = {
  lang: `fr`,
  description: `Des programmes sportifs pour tous !`,
  title: `Mon Programme`,
}

export default SEO;
