import {Grid, makeStyles} from "@material-ui/core";

import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

function DesktopNavBar({texts, routes, children, logo, leftContent = null, rightContent = null}) {
  
  const history = useHistory();
  
  const [pathname, setPathname] = useState(history.location.pathname)

  const useStyle = makeStyles((theme) => ({
    root : {
      width: "100vw",
      height: "100vh",
    },
    topBanner : {
      width: "100vw",
      height: "135px",
      background : theme.palette.primary.main,
      "& > div" : {
        height : "50%",
      }
    },
    logoContainer : {
      display : "flex",
      justifyContent : "center",
      alignItems : "center",
      "& img" : {
        height : "80%",
        width : "auto"
      }
    },
    menuContainer : {
      display : "flex",
      alignItems : "center",
      justifyContent : "space-between",
      padding : "0px 10%"
    },
    accountPopover : {
      padding : "10px",
      backgroundColor : theme.palette.primary.popover,
      color : theme.palette.text.light
    },
    drawer : {
      overflow : "auto",
      position : "fixed",
      top : "135px",
      width : "100vw",
      height : "calc(100% - 135px)"
    },
    paper: {
      overflowY: "hidden!important"
    }
  }))
  const classes = useStyle()
  
  useEffect(() => {
    setPathname(history.location.pathname)
  }, [history.location.pathname])
  
  const goToRoute = (route) => {
    history.push(route)
    setPathname(route)
  }

  return (
      <div className={classes.root}>
        <Grid container className={classes.topBanner}>
          <Grid item xs={2}>
            {rightContent}
          </Grid>
          <Grid item xs={8} className={classes.logoContainer}>
            <img src={logo} alt="Logo"/>
          </Grid>
          <Grid item xs={2}>
            {leftContent}
          </Grid>
          
          <Grid item xs={2}/>
          <Grid item xs={8} className={classes.menuContainer}>
            {(texts && texts.length > 0) && texts.map((text, index) => (
                <MenuItem text={text} route={routes[index]} pathname={pathname} handleClick={() => goToRoute(routes[index])} key={index}/>
            ))}
          </Grid>
          <Grid item xs={2}/>
        </Grid>
        <div className={classes.drawer}>
          {children}
        </div>
      </div>
  );
}

const MenuItem = ({text, route, pathname, handleClick}) => {
  
  const useStyle = makeStyles((theme) => ({
    menuIcon : {
      color : theme.palette.text.light,
      flexGrow : 1,
      flexShrink : 1,
      textAlign : "center",
      margin : "5px",
      height : "100%",
      display : "flex",
      alignItems : "center",
      justifyContent : "center",
      fontWeight : 600,
      cursor : "pointer"
    },
    selected : {
      color : theme.palette.secondary.main
    }
  }))
  const classes = useStyle()
  
  return (
      <div
          className={(pathname === route ? classes.selected : "") + " " + classes.menuIcon}
          onClick={handleClick}
      >
        {text}
      </div>
  )
}

export default DesktopNavBar;
