import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';

import ProgramsList from '../Components/Programs/ProgramsList';
import Layout from "../Components/Layout";
import Search from "../Components/Programs/Search";
import Selector from "../Components/Selector";
import SEO from "../Components/SEO";

import * as ProgramModel from '../Model/program.model'
import {filters} from '../secret-client.json'
import Button from "../Components/Button";

export default function NosProgrammes() {
    const useStyles = makeStyles((theme) => ({
        programsListComponent: {  
            height : "100%",
            width : "100%",
            position : "relative",
        },
        backgroundModalFilters:{
            position : "absolute",
            display : "block",
            height : "100%",
            width : "100%",
            top : "-2%",
            backgroundColor : "#000000a3",
		    
        },
        modalFilters:{
            position : "fixed",
            bottom : 0,
            height : "70%",
            width : "100%",
            backgroundColor : theme.palette.primary.background,
            borderRadius: "50px 50px 0 0",
            textAlign: "center",
        },
        modalContains:{
            position:"relative",
            display:"flex",
            flexDirection:"column",
            alignItems:"center"
        },
        buttonFilter: {
            borderRadius: "50px",
            width : "20%",
		    [theme.breakpoints.down('sm')]: {width: "30%"},
            color: "white",
            padding : "10px",
            marginTop : "20px"
        },
        cancelIcon: {
          position:"absolute",
          top:"10px",
          left:"10px",
          color:"red",
          height:"40px",
          width:"40px"  
        }
    }));
    const classes = useStyles();

    //console.log("NosProgrammes in this page")

    const [programs, setPrograms] = useState([])
    const [programsDisplay, setProgramsDisplay] = useState([])
	//console.log(programsDisplay)
    
	const [modalDisplay, setModalDisplay] = useState(false)

	const [textFilter, setTextFilter] = useState("")
	const [sexFilter, setSexFilter] = useState("")
	const [seanceFilter, setSeanceFilter] = useState("")
	const [niveauFilter, setNiveauFilter] = useState("")
	const [tagFilter, setTagFilter] = useState("")
	const [objectifFilter, setObjectifFilter] = useState("")

    const valueSexFilter = ["H", "F", "HF"];
    const valueSeanceFilter = ["1", "2", "3", "4", "5", "6", "7+"];
    const valueNiveauFilter = ["Débutant", "Intermédiaire", "Expert"];
    const valueTagFilter = ["Pecs", "Dos", "Jambes", "Biceps", "Triceps", "Epaules", "Trapèzes", "Abdominaux", "Ischio", "Quadriceps", "Mollets", "haut du corps"];
    const valueObjectifFilter = ["Prise de masse", "Renforcement", "Sèche", "Performance", "Abdominaux"];

    //console.log(valueSeanceFilter)

    useEffect(() => {
        ProgramModel
            .getAll()
            .then(setPrograms)
    }, []);

    //Filtres & search bar 
    useEffect(()=>{
		const programsFiltered= programs.filter(
            (program) => {
                let toKeep = true;
                if(!program.name.toLowerCase().includes(textFilter.toLowerCase())){
                    toKeep=false
                }
                if(sexFilter !== "" && sexFilter !== program.sex){
                    toKeep = false
                }
                if(seanceFilter !== "" && seanceFilter !== program.nbSeance.toString()){
                    toKeep = false
                }
                if(niveauFilter !== "" && niveauFilter !== program.level){
                    toKeep = false
                }
                if(seanceFilter === "1" && tagFilter !== "" &&  !program.tags.includes(tagFilter)){
                    toKeep = false
                }
                if(objectifFilter !== "" && objectifFilter !== program.objective){
                    toKeep = false
                }
                return toKeep
            }
        );
		setProgramsDisplay(programsFiltered)
	}, [programs, textFilter, sexFilter, seanceFilter, niveauFilter, tagFilter, objectifFilter])

    let modal;
    if (modalDisplay) {
        modal =  
          <div className={classes.backgroundModalFilters}>
              <div className={classes.modalFilters}>
                  <div className={classes.modalContains}>
                      <h3>Filtres</h3>
                      <Selector name={"Sexe"} value={sexFilter} onChange={setSexFilter} values={valueSexFilter}/>
                      <Selector name={"Seance"} value={seanceFilter} onChange={setSeanceFilter} values={valueSeanceFilter}/>
                      <Selector name={"Niveau"} value={niveauFilter} onChange={setNiveauFilter} values={valueNiveauFilter}/>
                      {
                          seanceFilter === "1" && (
                              <Selector name={"Muscle"} value={tagFilter} onChange={setTagFilter} values={valueTagFilter}/>
                          )
                      }
                      <Selector name={"Objectif"} value={objectifFilter} onChange={setObjectifFilter} values={valueObjectifFilter}/>
					  <Button style={{padding: "10px 25px", marginTop: "5px"}} onClick={() => { setModalDisplay(false)}} >Valider</Button>
                      <CancelIcon className={classes.cancelIcon} onClick={() => {
                      	  setModalDisplay(false)
						  setSexFilter("")
						  setNiveauFilter("")
						  setSeanceFilter("")
						  setObjectifFilter("")
						  setTagFilter("")
                      }}/>
                  </div>
              </div>
          </div>
    }
      

    return (
        <div className={classes.programsListComponent}>
            <Layout>
                <SEO/>
                <Search setTextFilter={setTextFilter} textFilter={textFilter} setModalDisplay={setModalDisplay} modalDisplay={modalDisplay}/>
                <ProgramsList programsList={programsDisplay}/>
            </Layout>
            {modal}
        </div>
    );
}
