import {makeStyles} from "@material-ui/core";
import {useEffect, useState} from "react";
import {CloseRounded} from "@material-ui/icons";

function MobileSideBar({children, open : openProp, handleClose}) {
	const [open, setOpen] = useState(false)
	
	useEffect(() => {
		setOpen(openProp)
	}, [openProp])
	
	const useStyle = makeStyles((theme) => ({
		root: {
			"--blur" : open ? "3px" : "0px",
			opacity : open ? "1" : "0",
			position : "fixed",
			top : "0px",
			left : open ? "0vw" : "-110vw",
			width : "110vw",
			height : "100vh",
			background : "rgba(101, 106, 107,0.62)",
			backdropFilter : "blur(var(--blur))",
			transition : "all 150ms ease-in 150ms, left 10ms ease-in " + open ? "300ms" : "",
			borderRadius : "0px 50px 50px 0px",
		},
		menu : {
			borderRadius : "0px 50px 50px 0px",
			position : "fixed",
			height: "100vh",
			backgroundColor : theme.palette.primary.main,
			color : theme.palette.text.light,
			top : "0px",
			right : open ? "25vw" : "100vw",
			left : "-1px",
			transition : "right 150ms ease-in",
			"&:before" : {
				content : "\"\"",
				borderRadius : "0px 50px 50px 0px",
				position : "absolute",
				right : open ? "-8px" : "0px",
				width : "100%",
				height : "100%",
				backgroundColor : theme.palette.primary.main,
				opacity : "0.47",
				zIndex : "-1",
				transition : "right 150ms ease-in",
			}
		},
		menuHeader : {
			width : "100%",
			height : "100px",
			display : "flex",
			justifyContent : "flex-end",
			alignItems : "center",
		},
		menuCloseButton : {
			marginRight : "25px",
			height : "50%",
			maxHeight : "50px",
			maxWidth : "50px",
			backgroundColor : theme.palette.secondary.main,
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "5px",
			transform : "scale(0.8)",
			borderRadius : "10px",
			"& svg" : {
				height : "100%",
				width : "auto"
			}
		}
	}))
	const classes = useStyle()
	
	return (
		<div className={classes.root}>
			<div className={classes.menu}>
				<div className={classes.menuHeader}>
					<div className={classes.menuCloseButton} onClick={() => handleClose()}>
						<CloseRounded/>
					</div>
				</div>
				<div style={{textAlign: "center"}}>
					{children}
				</div>
			</div>
		</div>
	);
}

export default MobileSideBar;
