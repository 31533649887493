import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import Layout from "../Components/Layout";
import SEO from "../Components/SEO";

import * as BlogModel from '../Model/blog.model'
import PostsList from "../Components/Blog/BlogPostsList";

export default function BlogPage() {
	
	const CATEGORIES = [
		{name : "Style de vie et nutrition", id : "lifestyleEtNutrition"},
		{name : "Santé", id : "sante"},
		{name : "Pratiques sportives", id : "pratiqueSportive"}
	]
	
	const [category, setCategory] = useState(CATEGORIES[0])
	
    const useStyles = makeStyles((theme) => ({
        postListComponent: {
            height : "100%",
            width : "100%",
            position : "relative",
        },
    }));
    const classes = useStyles();

    const [posts, setPosts] = useState([])

    useEffect(() => {
		BlogModel
            .getAllOrderByRecent()
            .then(setPosts)
    }, []);

    return (
        <div className={classes.postListComponent}>
            <Layout column horizontalCenter>
            	<SEO/>
				<CategoriesMenu
					options={CATEGORIES}
					value={category}
					onChange={setCategory}
				/>
				<Layout width={"100%"}>
					<PostsList postsList={posts.filter(post => post.category === category.id)}/>
				</Layout>
            </Layout>
        </div>
    );
}

const CategoriesMenu = ({options, value, onChange}) => {
	
	const useStyles = makeStyles((theme) => ({
		categoriesMenuRoot : {
			width : "95%",
			maxWidth : "600px",
			display : "flex",
			justifyContent : "space-between"
		},
		categoriesMenuItem : {
			borderRadius : "7px",
			background : theme.palette.primary.brighter,
			color : theme.palette.text.light,
			width : "calc(30% - 15px)",
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
			textAlign : "center",
			padding : "10px"
		},
		categoriesMenuItemSelected : {
			color : theme.palette.text.light,
			background : theme.palette.secondary.main,
			fontWeight : 600
		}
	}));
	const classes = useStyles();
	
	return (
		<div className={classes.categoriesMenuRoot}>
			{options.map(option => (
				<div className={classes.categoriesMenuItem + " " + (option.id !== value.id ? "" : classes.categoriesMenuItemSelected)} onClick={() => onChange && onChange(option)} key={option.id}>
					{option.name}
				</div>
			))}
		</div>
	)
}
