import React, {useContext} from "react";
import {Switch, Route, Redirect} from "react-router-dom";

import UserContext from "./Context/user.context";


import {CircularProgress} from "@material-ui/core";
import {hasPropEqual} from "./Utils/object.utils";
import Layout from "./Components/Layout";

import PanierPage from "./Pages/Panier.page";
import CheckoutRedirection from "./Pages/CheckoutRedirection.page";
import MesProgrammesPage from "./Pages/Customer/MesProgrammes.page";
import ProgramDetailPage from "./Pages/ProgramDetail.page";
import CoachDetailPage from "./Pages/CoachDetail.page";
import NosProgrammes from "./Pages/NosProgrammes.page";
import CreationProgrammePage from "./Pages/Coach/CreationProgramme.page";
import ParametresPage from "./Pages/Customer/Parametres.page";
import BlogPage from "./Pages/Blog.page";
import BlogPostPage from "./Pages/BlogPost.page";
import MesArticlesPage from "./Pages/Coach/MesArticles.page";
import CreationArticlePage from "./Pages/Coach/CreationArticle.page";
import CoachsPage from "./Pages/Admin/Coachs.page";
import Accueil from "./Pages/Accueil.page";
import ForgotPasswordPage from "./Pages/ForgotPassword.page";
import NosCoachPage from "./Pages/NosCoachs.page";

const LOADING_COMPONENT = (<Layout horizontalCenter verticalCenter><CircularProgress color="secondary"/></Layout>)

const RouteComponent = () => {
	//console.log("routeComponent RouteComponent")
	return (
		<Switch>
			<Route path="/accueil" exact component={Accueil}/>
			<Route path="/mot-de-passe-oublie" exact component={ForgotPasswordPage}/>
			<Route path="/nos-programmes" exact component={NosProgrammes}/>
			<Route path="/nos-coachs" exact component={NosCoachPage}/>
			<Route path="/blog" exact component={BlogPage}/>
			<Route path="/coach/*" component={CoachDetailPage}/>
			<Route path="/programme/*" component={ProgramDetailPage}/>
			<Route path="/panier" exact component={PanierPage}/>
		 	<Route path="/paiement" exact component={CheckoutRedirection}/>
			<Route path="/post/*" component={BlogPostPage}/>
			
			<AuthRoute path="/mes-programmes" allowedType={["customer", "coach", "admin"]} component={MesProgrammesPage}/>
			<AuthRoute path="/parametres" allowedType={["customer", "coach", "admin"]} component={ParametresPage}/>
			
			<AuthRoute path="/creation-programme" allowedType={["coach", "admin"]} requiredAccess={{programmes: true}} component={CreationProgrammePage}/>
			<AuthRoute path="/mes-articles" allowedType={["coach", "admin"]} requiredAccess={{blog: true}} component={MesArticlesPage}/>
			<AuthRoute path="/creation-article" allowedType={["coach", "admin"]} requiredAccess={{blog: true}} component={CreationArticlePage}/>
			
			<AuthRoute path="/coachs" allowedType={["admin"]} component={CoachsPage}/>
			<Route>
				<NotFoundPage/>
			</Route>
		</Switch>
	)
}

const AuthRoute = ({component: Component, allowedType, requiredAccess={}}) => {
	const user = useContext(UserContext)
	
	//User has isPending
	if(user.hasOwnProperty("isPending") && user.isPending)
		return LOADING_COMPONENT

	//Auth Route required connected user
	if(user.hasOwnProperty("isConnected") && !user.isConnected){
		//console.log("User not connected")
		return <Redirect to="/accueil"/>
	}
	
	//Check if user has a valid type
	if(!allowedType.includes(user.type)) {
		//console.log("User type not allowed")
		return <Redirect to="/accueil"/>
	}
	//Check required access object correspond to user allowAccess
	if(!hasPropEqual(user.allowAccess, requiredAccess)){
		//console.log("User not allowed")
		return <Redirect to="/accueil"/>
	}

	return <Component/>
}

const NotFoundPage = () => (
	<h3>Désolé, la page demandée est introuvable 😞</h3>
)

export default RouteComponent;