import React, {useContext, useEffect, useState} from 'react'
import {Button, Grid, Link} from "@material-ui/core";

import firebase from '../../Api/firebase.api';
import UserContext from "../../Context/user.context";
import {AccountCircle} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import * as UserModel from "../../Model/user.model";
import {makeStyles} from "@material-ui/core/styles";

const AccountConnectedMenu = ({style, handleClose}) => {
	
	const currentUser = firebase.auth().currentUser;
	const [userData, setUserData] = useState(null);
	//console.log("userData", userData)
	
	useEffect(() => {
		UserModel.getFromUid(currentUser.uid).then((user) => setUserData(user))
	}, [currentUser])
	
	if(!currentUser){
		return (<></>)
	}
	
	const useStyles = makeStyles((theme) => ({
		linkMailTo: {
			padding : "10px 20px",
			backgroundColor : theme.palette.secondary.main,
			borderRadius : "40px",
			textDecoration : "none",
			color : theme.palette.text.light,
			fontWeight : 600
		}
	}));
	const classes = useStyles();
	
	const history = useHistory()
	
	return(
		<section id="mobile-profil-drawer" style={style}>
			<Grid container justify="center" spacing={0} direction={"column"} style={{marginTop: "5vh"}}>
				<Grid item>
					<AccountCircle style={{fontSize: "5em"}}/>
				</Grid>
				<Grid item>
					<h2>
						{currentUser.displayName === null ? "John Smith" : currentUser.displayName}
					</h2>
					{
						(userData && userData.type === "coach") && (
							<>
								<h3>Balance : {userData.balance && parseFloat(userData.balance).toFixed(2)}€</h3>
								<a
									className={classes.linkMailTo}
									href="mailto:admin@monprogramme.fr"
								>
									Retirer
								</a>
							</>
						)
					}
				</Grid>
			</Grid>
			
			<hr style={{margin: "20px 0px"}}/>
			
			<Grid
				container
				justify="center"
				spacing={3}
				direction={"column"}
				style={{
					textAlign: "center"
				}}
			>
				
				{
					(userData && userData.type !== "customer") && (
						<Grid item>
							<a
								style={{
									color: "white",
									textDecoration: "none",
									cursor : "pointer"
								}}
								onClick={() => {
									history.push("/coach/" + userData.userId)
									handleClose && handleClose();
								}}
							>
								Mon profil
							</a>
						</Grid>
					)
				}
				<Grid item>
					<a
						onClick={() => {
							history.push("/mes-programmes")
							handleClose && handleClose();
						}}
						style={{
							color: "white",
							textDecoration: "none",
							cursor : "pointer"
						}}
					>
						Mes programmes
					</a>
				</Grid>
				
				{
					(userData && userData.type !== "customer" && userData.allowAccess.blog) && (
						<Grid item>
							<a
								style={{
									color: "white",
									textDecoration: "none",
									cursor : "pointer"
								}}
								onClick={() => {
									history.push("/mes-articles")
									handleClose && handleClose();
								}}
							>
								Mes articles
							</a>
						</Grid>
					)
				}
				
				<Grid item>
					<a
						onClick={() => {
							history.push("/parametres")
							handleClose && handleClose();
						}}
						style={{
							color: "white",
							textDecoration: "none",
							cursor : "pointer"
						}}
					>
						Paramètres
					</a>
				</Grid>
			</Grid>
			
			{
				(userData && userData.type === "admin") && (
					<>
						<hr style={{margin: "20px 0px"}}/>
						
						<Grid
							container
							justify="center"
							spacing={3}
							direction={"column"}
							style={{
								textAlign: "center"
							}}
						>
							<Grid item>
								<a
									style={{
										color: "white",
										textDecoration: "none",
										cursor : "pointer"
									}}
									onClick={() => {
										history.push("/coachs")
										handleClose && handleClose();
									}}
								>
									Gerer les coachs
								</a>
							</Grid>
						</Grid>
					</>
				)
			}
			
			<hr style={{margin: "20px 0px"}}/>
			
			<Grid
				container
				justify="center"
				spacing={3}
				direction={"column"}
				style={{
					textAlign: "center"
				}}
			>
				<Grid item>
					<Button
						color="secondary"
						variant="contained"
						style={{width: "72%",borderRadius: "1rem"}}
						onClick={()=>{
							handleClose && handleClose()
							history.push("/accueil");
							firebase.auth().signOut();
						}}
					>
						<b>Se déconnecter</b>
					</Button>
				</Grid>
			</Grid>
		</section>
	)
}

const AccountNotConnectedMenu = ({handleChangeSection, style}) => (
	<Grid container justify="center" direction={"column"} style={style}>
		<Grid item>
			<Button
				color="secondary"
				variant="contained"
				style={{width: "100%",borderRadius: "1rem", margin : "15px 0px"}}
				onClick={()=>{handleChangeSection("login")}}
			>
				<b>Se connecter</b>
			</Button>
		</Grid>
		<Grid item>
			<Button
				color="secondary"
				variant="contained"
				style={{width: "100%",borderRadius: "1rem", margin : "15px 0px"}}
				onClick={()=>{handleChangeSection("register")}}
			>
				<b>S'inscrire</b>
			</Button>
		</Grid>
	</Grid>
)

export {AccountNotConnectedMenu, AccountConnectedMenu}