import {makeStyles} from "@material-ui/core";
import MobileTopNavBar from "./MobileTopNavBar";
import MobileBottomNavBar from "./MobileBottomNavBar";

function NavBar({children, title = null, topIconLeft = null, topHandleLeft = null, topIconRight = null, topHandleRight = null, bottomIcons, bottomRoutes, }) {
	
	const useStyle = makeStyles((theme) => ({
		root: {
			width: "100vw",
			height: "100vh",
			position : "relative",
		},
		drawer : {
			overflow : "auto",
			position : "fixed",
			top : "60px",
			width : "100vw",
			height : "calc(100% - 120px)"
		}
	}))
	const classes = useStyle()
	
	return (
		<div className={classes.root}>
			<MobileTopNavBar title={title} iconLeft={topIconLeft} handleLeft={topHandleLeft} iconRight={topIconRight} handleRight={topHandleRight}/>
			<div className={classes.drawer}>
				{children}
			</div>
			<MobileBottomNavBar icons={bottomIcons} routes={bottomRoutes}/>
		</div>
	);
}

export default NavBar;
