import {useContext, useEffect, useState} from "react";
import {
	AccountCircleRounded,
	BookRounded,
	FitnessCenterRounded,
	HomeRounded, PeopleRounded,
	ShoppingCartRounded
} from "@material-ui/icons";
import {Hidden, makeStyles, Popover, useTheme} from "@material-ui/core";

import PwaNavBar from "./PwaNavBar/PwaNavBar";
import DesktopNavBar from "./DesktopNavBar/DesktopNavBar";
import MobileSideBar from "./PwaNavBar/MobileSideBar";

import firebase from '../../Api/firebase.api'

import {AccountConnectedMenu, AccountNotConnectedMenu} from "./AccountMenu";

import RegisterForm from "../Form/RegisterForm";
import LoginForm from "../Form/LoginForm";
import {useHistory, useLocation} from "react-router-dom";
import UserContext from "../../Context/user.context";

import * as ShoppingCartModel from "../../Model/shopping-cart.model";

import logo from "../../Assets/logo.png"
import ShoppingIcon from "./ShoppingIcon";

const MENU = [
	{
		icon : (<HomeRounded/>),
		text : "Accueil",
		route : "/accueil"
	},
	{
		icon : (<FitnessCenterRounded/>),
		text : "Nos programmes",
		route : "/nos-programmes"
	},
	{
		icon : (<BookRounded/>),
		text : "Blog",
		route : "/blog"
	},
	{
		icon : (<PeopleRounded/>),
		text : "Nos coachs",
		route : "/nos-coachs"
	}
]

const PlatformNavBar = ({children}) => {
	const [accountMenuOpen, setAccountMenuOpen] = useState(false);
	const [title, setTitle] = useState(false);
	const [openPopover, setOpenPopover] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [nbArticlesInShoppingCart, setNbArticlesInShoppingCart] = useState(null)

	var currentUser = useContext(UserContext)

	useEffect(() => {
		const prepare = async () => {
			setNbArticlesInShoppingCart(await ShoppingCartModel.get_nb_items(currentUser))
		}
		prepare()
	}, [currentUser])
	
	const history = useHistory();

	const useStyle = makeStyles((theme) => ({
		root: {
			backgroundColor : theme.palette.primary.background
		},
		shoppingCartIcon : {
			color : (history.location.pathname === "/panier" ? theme.palette.secondary.main : theme.palette.text.light) + " !important"
		},
		accountPopover: {
			backgroundColor: theme.palette.primary.background,
			padding: "1rem"
		},
		accountAndShoppingContainer : {
			width : "100%",
			height : "100%",
			color : theme.palette.text.light,
			display : "flex",
			flexDirection : "column",
			justifyContent : "center",
			alignItems : "center",
			cursor : "pointer",
			"& p" : {
				textAlign : "center",
				margin : "0px",
				fontWeight : 600,
				fontSize : "80%"
			}
		},
	}))
	const classes = useStyle()

	const location = useLocation()

	useEffect(()=>{
		setTitle(location.pathname
			.split("/")[1]
			.split("-")
			.map(word => {
				word = word.replace("/", "")
				return (word[0].toUpperCase() + word.substring(1))
			})
			.join(" ")
		)
	}, [location.pathname])
	
	const user = useContext(UserContext)
	
	const handleClickPopover = (event) => {
		setAnchorEl(event.currentTarget);
		setOpenPopover(!openPopover)
	};

	return (
		<div className={classes.root}>
			<Hidden mdUp>
				<PwaNavBar
					title={title}
					topIconLeft={(<AccountCircleRounded/>)}
					topHandleLeft={() => {
						setAccountMenuOpen(true)
					}}
					topIconRight={(
						<ShoppingIcon
							className={classes.shoppingCartIcon}
							nbElement={nbArticlesInShoppingCart}
						/>
						)}
					topHandleRight={() => {
						history.push("/panier")
					}}
					bottomIcons={MENU.map(item => item.icon)}
					bottomRoutes={MENU.map(item => item.route)}
				>

					{children}
				</PwaNavBar>
				<MobileSideBar open={accountMenuOpen} handleClose={() => {setAccountMenuOpen(false)}}>
					<h3 style={{fontWeight: 500, marginTop: "0.2rem"}}>Mon compte</h3>
					<PopoverContent
						handleClose={() => {setAccountMenuOpen(false)}}
						accountMenuOpen={accountMenuOpen}
						mobile
					/>
				</MobileSideBar>
			</Hidden>
			<Hidden smDown>
				<DesktopNavBar
					texts={MENU.map(item => item.text)}
					routes={MENU.map(item => item.route)}
					logo={logo}
					rightContent={(
						<div className={classes.accountAndShoppingContainer} onClick={handleClickPopover}>
							<AccountCircleRounded/>
							<p>Mon compte</p>
							<Popover
								style={{overflowY: "hidden"}}
								open={openPopover}
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'center',
								}}
								classes={{paper: classes.accountPopover}}
							>
								<div onClick={(event)=>event.stopPropagation()}>
									<PopoverContent
										handleClose={() => {setAccountMenuOpen(false)}}
										accountMenuOpen={accountMenuOpen}
									/>
								</div>
							</Popover>
						</div>
					)}
					leftContent={(
						<div className={classes.shoppingCartIcon + " " + classes.accountAndShoppingContainer} onClick={() => history.push("/panier")}>
							<ShoppingCartRounded/>
							<p>Mon panier{nbArticlesInShoppingCart ? ` (${nbArticlesInShoppingCart})` : ""}</p>
						</div>
					)}
				>
					{children}
				</DesktopNavBar>
			</Hidden>
		</div>
	);
}

const PopoverContent = ({accountMenuOpen, mobile=false, handleClose}) => {
	const [section, setSection] = useState("")
	const isConnected = firebase.auth().currentUser !== null;
	const theme = useTheme();

	useEffect(()=>{
		setSection("")
	}, [accountMenuOpen])

	if(!isConnected && section === "register")
		return <RegisterForm handleClose={handleClose}/>;

	if(!isConnected && section === "login")
		return <LoginForm handleClose={handleClose}/>;

	if(isConnected)
		return <AccountConnectedMenu handleClose={handleClose} style={{
			textAlign: "center",
			color : theme.palette.text.light,
			width : mobile ? "100%" : "250px"
		}}/>

	return <AccountNotConnectedMenu handleChangeSection={setSection} style={{
		marginTop : mobile ? "20vh" : "0vh",
		width : mobile ? "65%" : "250px",
		marginLeft : mobile ? "17.5%" : "0px",
		padding : mobile ? "0px" : "15px"
	}}/>
}

export default PlatformNavBar;
export {PopoverContent};
