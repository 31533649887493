import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";

function MobileBottomNavBar({icons, routes}) {
	
	const history = useHistory();
	
	const [pathname, setPathname] = useState(history.location.pathname)
	
	const useStyle = makeStyles((theme) => ({
		root: {
			position : "fixed",
			bottom : "0px",
			width: "100vw",
			height: "60px",
			backgroundColor : theme.palette.primary.main,
			display : "flex",
			alignItems : "center",
			justifyContent : "space-between",
			borderRadius : "15px 15px 0px 0px"
		}
	}))
	const classes = useStyle()
	
	useEffect(() => {
		setPathname(history.location.pathname)
	}, [history.location.pathname])
	
	const goToRoute = (route) => {
		history.push(route)
		setPathname(route)
	}
	
	return (
		<div className={classes.root}>
			{
				(icons && icons.length > 0) && icons.map((icon, index) => (
					<MenuIcon route={routes[index]} icon={icon} pathname={pathname} handleClick={() => goToRoute(routes[index])} key={index}/>
				))
			}
		</div>
	);
}

const MenuIcon = ({icon, route, pathname, handleClick}) => {
	
	const useStyle = makeStyles((theme) => ({
		menuIcon : {
			color : theme.palette.text.light,
			flexGrow : 1,
			flexShrink : 1,
			textAlign : "center",
			margin : "5px",
			height : "100%",
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			"& svg" : {
				height : "50%",
				width : "auto"
			}
		},
		selected : {
			color : theme.palette.secondary.main
		}
	}))
	const classes = useStyle()
	
	return (
		<div
			className={(pathname === route ? classes.selected : "") + " " + classes.menuIcon}
			onClick={handleClick}
		>
			{icon}
		</div>
	)
}

export default MobileBottomNavBar;
