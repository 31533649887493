import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../Card";
import firebase from "firebase";
import StarRatings from 'react-star-ratings';

export default function CardCoach({fullname, image, userId, ...props}) {
	
	const [averageRating, setAverageRating] = useState(0);
	const [nbNote, setNbNotes] = useState(0);
	
    const useStyles = makeStyles((theme) => ({
		programComponent: {
			width : "200px",
			margin: "10px",
			[theme.breakpoints.down("sm")]: {
				width: "130px",  },
		},
		footer: {
			backgroundColor: "rgba(255,255,255,0.5)",
			color: theme.palette.text.dark,
			position : "absolute",
			bottom : "0px",
			height : "60%",
			width : "100%",
			"& h6" : {
				fontSize : "20px",
				[theme.breakpoints.down("sm")]: {
					fontSize : "15px"
				},
			},
			"& p" : {
				fontSize : "15px",
				[theme.breakpoints.down("sm")]: {
					fontSize : "10px"
				},
			}
		},
        label: {
            margin: "2%",
            padding: "0% 2%",
        },
		note: {
			display: "flex",
			color: theme.palette.secondary.main,
			alignItems: "center",
			margin: "5px 0",
			"& p": {
				color: theme.palette.text.light,
				margin: "0 10px",
			},
		},
		star:{
			[theme.breakpoints.down("sm")]: {
				width: "0.8em",  },
		},
		title: {
			color: theme.palette.text.light,
			margin: "5px 0",
		},
    }));
    const classes = useStyles();
    
    useEffect(() => {
    	firebase.firestore().collection("Programmes").where("coachUserId", "==", userId).get().then((snapshot) => {
    		
    		let averageRatingPerProgram = snapshot.docs.map(doc =>
				doc.data().reviews.map(review => review.review).reduce((a, b) => a + b, 0) / doc.data().reviews.length
			).filter(programAverageReview => !isNaN(programAverageReview))
   
			setAverageRating(
				(averageRatingPerProgram.reduce((a, b) => a + b, 0) / averageRatingPerProgram.length)
			)
		
			let nbRatingPerProgram = snapshot.docs.map(doc => doc.data().reviews.length)
			
			setNbNotes(nbRatingPerProgram.reduce((a, b) => a + b, 0))
		})
	}, [userId])
    
    return (
        <div className={classes.programComponent}>
			<Card image={image} style={{margin : "10px"}} {...props}/>
			{
				(!isNaN(averageRating) && nbNote > 0 )&& (
					<div className={classes.note}>
						<StarRatings
							rating={averageRating}
							starRatedColor="yellow"
							numberOfStars={5}
							name='rating'
							starDimension="15px"
							starSpacing="2px"
						/>
						<p>({nbNote})</p>
					</div>
				)
			}
			<div className={classes.title}>{fullname}</div>
		</div>
		
    );
}
