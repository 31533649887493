import React, {useEffect, useState} from "react";
import { useHistory } from "react-router";
import Program from "./Program";
import {makeStyles} from "@material-ui/core/styles";

const ProgramsList = ({programsList}) => {
    const history = useHistory();
    
	const useStyles = makeStyles((theme) => ({
        ProgramListComponent: {  
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-around",
        },
    }));
    const classes = useStyles();

    return (
        <div className={classes.ProgramListComponent}>
			{
				programsList.length > 0 && programsList.map((program, index) => (
					<Program
						onClick={()=>history.push("/programme/" + program.programId)}
						key={index}
						objective={program.objective}
						sex={program.sex}
						nbNote={program.reviews.length ?? 0}
						title={program.name}
						price={program.price && program.price.toFixed(2) + "€"}
						image={program.imageLink}
						sumNote={program.reviews.map(review => review.review).reduce((a, b) => a + b, 0)}
					/>
				))
			}
        </div>
    );
};

export default ProgramsList;
