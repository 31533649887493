import firebase from '../Api/firebase.api.js'
import {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import * as ShoppingCartModel from "../Model/shopping-cart.model";
import UserContext from "../Context/user.context";
import {Grid, makeStyles} from "@material-ui/core";
import Button from "../Components/Button";
import {CreditCardRounded, DeleteRounded} from "@material-ui/icons";

const PanierPage = () => {
	
	var currentUser = useContext(UserContext)
	const history = useHistory();
	
	const [programs, setPrograms] = useState([])
	const [products, setProducts] = useState([])
	
	const [total, setTotal] = useState(0);
	
	useEffect(() => {
		const prepare = async () => {
			let goods = await ShoppingCartModel.get_content(currentUser)
			
			let somme = 0
			
			let programs = goods.filter(good => good.type === "programme")
			programs = (await Promise.all(programs.map(programGood => {
				return firebase.firestore().collection("Programmes").doc(programGood.id).get()
			}))).map(programDoc => {

				let quantity = (programs.filter(p => p.id === programDoc.id)[0] ?? {quantity: 0}).quantity
				
				somme += programDoc.data().price * quantity
				
				return {
					...programDoc.data(),
					docId: programDoc.id,
					quantity:quantity
				}
			})
			setPrograms(programs)
			
			let products = goods.filter(good => good.type === "product")
			products = (await Promise.all( products.map(productGood => {
				return firebase.firestore().collection("Products").doc(productGood.id).get()
			}))).map(productDoc => {
				
				let quantity = (products.filter(p => p.id === productDoc.id)[0] ?? {quantity: 0}).quantity
				
				somme += productDoc.data().price * quantity
				
				return {
					...productDoc.data(),
					docId: productDoc.id,
					quantity: quantity
				}
			})
			setProducts(products)
			
			setTotal(somme)
		}
		prepare();
	}, [currentUser])
	
	const useStyle = makeStyles((theme) => ({
		rootContainer : {
			width : "100%",
			height : "100%",
			display : "flex",
			justifyContent : "center"
		},
		root : {
			width : "calc(100% - 20px)",
			margin : "10px",
			maxWidth : "800px"
		},
		totalTitle : {
			marginBottom : "0px",
			marginTop : "0px"
		},
		totalPrice : {
			marginRight : "10px",
			textAlign : "right",
			marginTop : "0px"
		},
		totalContainer : {
			margin : "10px",
			width : "calc(100% - 20px)",
			display : "flex",
			justifyContent : "space-between",
			color : theme.palette.secondary.main
		},
		button : {
			width : "80%",
			marginLeft : "10%",
			padding : "15px 0px",
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			fontSize : "150%",
			"& p" : {
				margin : "0px",
				marginLeft : "10px"
			},
			"& svg" : {
				width : "30px",
				height : "30px"
			}
		},
		emptyCartMessage : {
			textAlign : "center"
		}
	}));
	const classes = useStyle()

	if(programs.length + products.length === 0){
		return (<h2 className={classes.emptyCartMessage}>Votre panier est vide</h2>)
	}

	return (
		<div className={classes.rootContainer}>
			<div className={classes.root}>
				<Part title={"Programmes"} articles={programs} displayQuantity={false} currentUser={currentUser}/>
				<Part title={"Produits"} articles={products} currentUser={currentUser}/>
				<div className={classes.totalContainer}>
					<h1 className={classes.totalTitle}>Total</h1>
					<h1 className={classes.totalPrice}>{total.toFixed(2)}€</h1>
				</div>
				<Button onClick={() => history.push("/paiement")} className={classes.button}>
					<CreditCardRounded/> <p>PAYER</p>
				</Button>
			</div>
		</div>
	)
}

const Part = ({title, articles, displayQuantity=true, currentUser}) => {
	
	const [subTotal, setSubTotal] = useState(0);
	
	useEffect(() => {
		var somme = 0;
		for(var article of articles){
			somme += article.price * article.quantity
		}
		setSubTotal(somme)
	}, [articles])
	
	const useStyle = makeStyles((theme) => ({
		root : {
			width : "100%",
			maxWidth : "800px"
		},
		title : {
			color : theme.palette.secondary.main
		},
		subTotalTitle : {
			marginBottom : "0px",
			marginTop : "0px"
		},
		subTotalPrice : {
			marginRight : "10px",
			textAlign : "right",
			marginTop : "0px"
		},
		subTotalContainer : {
			width : "100%",
			display : "flex",
			justifyContent : "space-between"
		}
	}));
	const classes = useStyle()
	
	if(!articles || articles.length === 0)
		return (<></>)
	
	return (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<h1 className={classes.title}>{title}</h1>
			</Grid>
			{
				articles.map((article, index) => (
					<PriceLine article={article} displayQuantity={displayQuantity} key={index} currentUser={currentUser}/>
				))
			}
			<Grid item xs={12} className={classes.subTotalContainer}>
				<h2 className={classes.subTotalTitle}>Sous-total</h2>
				<h2 className={classes.subTotalPrice}>{subTotal.toFixed(2)}€</h2>
			</Grid>
		</Grid>
	)
}

const PriceLine = ({article, displayQuantity=true, currentUser}) => {
	
	const useStyle = makeStyles((theme) => ({
		articleName : {
			marginTop : "0px",
			marginLeft : "10px",
			marginBottom : "0px",
			fontSize : "115%",
			fontWeight : "600"
		},
		articleDeleteContainer : {
			"&:hover" : {
				color : "red",
			},
			display : "flex",
			justifyContent : "center",
			alignItems : "flex-start",
			marginBottom : "15px",
			[theme.breakpoints.up("md")] : {
				marginBottom : "15px"
			}
		},
		articleNameContainer : {
			display : "flex",
			justifyContent : "flex-start",
			alignItems : "center",
			[theme.breakpoints.up("md")] : {
				marginBottom : "15px"
			}
		},
		quantityAndPriceContainer : {
			display : "flex",
			justifyContent : "space-between",
			marginBottom : "15px",
			fontSize : "110%"
		},
		quantity : {
			marginLeft : "10px",
			"& span" : {
				fontWeight : 600
			}
		},
		price : {
			margin : "0px",
			marginRight : "20px",
		}
	}));
	const classes = useStyle()

	const handleDelete = async () => {
		//console.log(article)
		await ShoppingCartModel.remove(currentUser, article.docId)
	}
	
	return (
		<>
			<Grid item xs={1} md={1} className={classes.articleDeleteContainer} onClick={handleDelete}>
				<DeleteRounded />
			</Grid>
			<Grid item xs={11} className={classes.articleNameContainer}>
				<Grid container>
					<Grid item xs={12} md={6} className={classes.articleNameContainer}>
						<p className={classes.articleName}>
							{article.name}
						</p>
					</Grid>

					<Grid item xs={12} md={6} className={classes.quantityAndPriceContainer}>
						<p className={classes.quantity}>
							{displayQuantity ? (<>Quantité : <span>{article.quantity}</span></>) : ""}
						</p>
						<p className={classes.price}>
							{article.price.toFixed(2)}€
						</p>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default PanierPage;