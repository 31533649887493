const hasPropEqual = (object, comparator) => {
	
	//console.log("object", object)
	//console.log("comparator", comparator)
	
	for(const prop in comparator){
		//console.log("prop", prop)
		//console.log("object[prop]", object[prop])
		//console.log("comparator[prop]", comparator[prop])
		if(object[prop] !== comparator[prop])
			return false
	}

	return true
}

export {
	hasPropEqual
}