import {Grid, makeStyles} from "@material-ui/core";

function MobileTopNavBar({title, iconLeft, handleLeft, iconRight, handleRight}) {
	
	const useStyle = makeStyles((theme) => ({
		root: {
			position : "fixed",
			width: "100vw",
			height: "60px",
			backgroundColor : theme.palette.primary.main,
			color : theme.palette.text.light,
			fontSize : "1.5em",
			fontWeight : "700",
			"& div" : {
				height : "100%",
				display : "flex",
				justifyContent : "center",
				alignItems : "center",
				"& svg" : {
					width : "50%",
					height : "auto",
					maxHeight : "80%"
				}
			}
		}
	}))
	const classes = useStyle()
	
	return (
		<Grid container className={classes.root}>
			<Grid item xs={2} onClick={handleLeft}>
				{iconLeft}
			</Grid>
			<Grid item xs={8}>
				{title}
			</Grid>
			<Grid item xs={2} onClick={handleRight}>
				{iconRight}
			</Grid>
		</Grid>
	);
}

export default MobileTopNavBar;
