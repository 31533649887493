import React, {useEffect, useState} from "react";
import { useHistory } from "react-router";
import CardCoach from "./CardCoach";
import {makeStyles} from "@material-ui/core/styles";

const CoachesList = ({choachesList}) => {
    const history = useHistory();
    
	const useStyles = makeStyles((theme) => ({
        PostListComponent: {  
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-around",
        },
    }));
    const classes = useStyles();
    return (
        <div className={classes.PostListComponent}>
			{
				choachesList.length > 0 && choachesList.map((coach, index) => (
					<CardCoach
						onClick={()=>history.push("/coach/" + coach.userId)}
						key={index}
						image={coach.profilPictureLink}
						fullname={coach.fullname}
						userId={coach.userId}
					/>
				))
			}
        </div>
    );
};

export default CoachesList;
