import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export default function Button(props) {
	
	const useStyles = makeStyles((theme) => ({
		root: {
			cursor : "pointer",
			background : props.color ? props.color : theme.palette.secondary.main,
			color : theme.palette.text.dark,
			borderRadius : "250px",
			textAlign : "center",
			fontWeight : 800,
			opacity : props.disabled ? 0.5 : 1
		},
	}));
    const classes = useStyles();

    return (
    <div {...props} className={props.className + " " + classes.root} >
        {props.children}
    </div>);
}
