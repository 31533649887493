import {ShoppingCartRounded} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core";


const ShoppingIcon = ({nbElement, ...props}) => {

	//console.log(nbElement)
	//console.log(nbElement ? "block" : "none")

	const useStyle = makeStyles((theme) => ({
		shoppingRoot : {
			position : "relative",
			width : "100%",
			height : "100%"
		},
		shoppingTag: {
			display : (nbElement ? "block" : "none") + " !important",
			backgroundColor : theme.palette.secondary.main,
			color : theme.palette.text.light,
			border : "3px solid " + theme.palette.primary.background,
			borderRadius : "100px",
			width : "20px",
			height : "20px !important",
			fontSize : "60%",
			position : "absolute",
			top : "50%",
			left : "50%",
			textAlign: "center",
		}
	}))
	const classes = useStyle()

	return (
		<div className={classes.shoppingRoot}>
			<ShoppingCartRounded {...props}/>
			<div className={classes.shoppingTag}>
				{nbElement}
			</div>
		</div>
	)
}

export default ShoppingIcon;