import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {CircularProgress, createMuiTheme, ThemeProvider} from "@material-ui/core";
import ConfigContext from "./Context/config.context";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#000000",
            brighter: "#232323",
            background : "#292929",
            popover : "#232323"
        },
        secondary: {
            main: "#f1c40f",
        },
        text: {
            dark: "#2b2d32",
            light: "#ecf0f1"
        },
	    danger : {
		    main: "#d00000",
	    }
    },
})

let config = {
    name : "Mon Programme"
}

ReactDOM.render(
    <Suspense fallback={<CircularProgress/>}>
        <BrowserRouter>
			<ThemeProvider theme={theme}>
				<ConfigContext.Provider value={{config: config}}>
					<App/>
				</ConfigContext.Provider>
			</ThemeProvider>
      </BrowserRouter>
    </Suspense>,
    document.getElementById('root')
);
