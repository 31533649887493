import React from "react";
import {makeStyles} from "@material-ui/core/styles";


export default function Layout({
    children,
    column=false,
    verticalCenter=false,
    horizontalCenter=false,
	width=null
}) {
	
	if(column){
		var tempBool = verticalCenter
		verticalCenter = horizontalCenter
		horizontalCenter = tempBool
	}
	
	const useStyles = makeStyles((theme) => ({
		layoutComponent: {
			margin: "2% auto",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: horizontalCenter ? "center" : "flex-start",
			alignItems: verticalCenter ? "center" : "flex-start",
			flexDirection : column ? "column" : "row",
			maxWidth: "900px",
			position: "relative",
			width : width ? width : "auto",
			[theme.breakpoints.down("sm")]: {
				margin: "2% 3%",
				width : width ? `calc(${width} - 6%)` : "auto",
				justifyContent: "space-between",
			},
		},
	}));
    const classes = useStyles();

    return (
    <div className={classes.layoutComponent}>
        {children}
    </div>);
}
