import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const Selector = ({name, value, onChange, values, multiple=false, renderValue, style, className}) => {
    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            color:"white",
            
            "& legend": {
                color:"white"
            },
            "& .MuiFormLabel-root":{
                color:"white"
            },
            "& .MuiSvgIcon-root":{
                color:"white"
            }
        },
        select: {
        	width : "100%",
            color:"white",
            borderColor:"white",
            minWidth:"200px",
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor : theme.palette.secondary.main + "!important",
                borderRadius : "50px"
            },
        },
        selectItem: {
            color:theme.palette.primary.main,
        },
        ".MuiPaper-root":{
            backgroundColor : "red"
        }
    }));
    const classes = useStyles();

    return (
        <FormControl variant="outlined" className={classes.formControl + " " + className} style={style}>
            <InputLabel>{name}</InputLabel>
            <Select
                multiple={multiple}
                className={classes.select}
                value={value}
                renderValue={renderValue || ((value) => multiple ? value.join(', ') : value)}
                onChange={(event) => {
                    //console.log("event.target.value", event.target.value)
                    onChange(event.target.value);
                }}
                label={name}
            >
                {values.length > 0 && values.map((v, index) => (
                    <MenuItem value={v} className={classes.selectItem} key={index}>
                        <em>{renderValue ? renderValue(v) : v}</em>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default Selector;
