import firebase from '../Api/firebase.api.js'
import {useContext, useEffect, useState} from "react";
import * as ShoppingCartModel from "../Model/shopping-cart.model";
import UserContext from "../Context/user.context";
import {makeStyles} from "@material-ui/core";

import secret_client from '../secret-client.json'

import { loadStripe } from "@stripe/stripe-js";
import {PopoverContent} from "../Components/NavBar/PlatformNavBar";
import {useHistory} from "react-router-dom";
const stripePromise = loadStripe(secret_client.stripe_client[process.env.NODE_ENV]);

const PagePanier = () => {
	
	var currentUser = useContext(UserContext)
	const history = useHistory()
	
	const [isConnected, setIsConnected] = useState(false)
	const [error, setError] = useState("")
	
	useEffect(() => {
		const prepare = async () => {
			let goods = await ShoppingCartModel.get_expanded_content(currentUser)
			//console.log("goods", goods)
			
			if(goods.length === 0 || (!currentUser.isPending && !currentUser.stripeCustomerId)){
				history.push("/accueil")
				return;
			}
			if(currentUser.isPending){
				return
			}

			var CreateStripeCheckoutSession = firebase.functions().httpsCallable("CreateStripeCheckoutSession")
			var checkoutSession = await CreateStripeCheckoutSession({
				success_url : "https://www.monprogramme.fr",
				shopping_cart : goods,
				userDoc : currentUser
			});
			
			const stripe = await stripePromise;
			
			//console.log(checkoutSession.data.id);
			
			const result = await stripe.redirectToCheckout({sessionId : checkoutSession.data.id})
			
			if (result.error) {
				setError(result.error.message)
			}
		}

		if(currentUser.isConnected){
			prepare();
			setIsConnected(true)
		}else{
			setIsConnected(false)
		}

	}, [currentUser])
	
	const useStyle = makeStyles((theme) => ({
		redirectMessageContainer : {
			width : "100%",
			height : "100%",
			display : "flex",
			alignItems : "center",
			justifyContent : "center"
		},
		redirectMessage : {
			textAlign : "center"
		},
		unconnectedRoot : {
			width : "100%",
			height : "100%",
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
			flexDirection : "column"
		}
	}));
	const classes = useStyle()

	if(isConnected){
		return (
			<div className={classes.redirectMessageContainer}>
				<h2 className={classes.redirectMessage}>
					Sécurisation du formulaire de paiement...
				</h2>
				<h4>{error !== "" && error}</h4>
			</div>
		)
	}else{
		return (
			<div className={classes.unconnectedRoot}>
				<h2>Pour continuer, veuillez vous connecter</h2>
				<PopoverContent/>
			</div>
		)
	}
}

export default PagePanier;