import Layout from "../../Components/Layout";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	ThemeProvider,
	useTheme
} from "@material-ui/core";
import {useDropzone} from "react-dropzone";
import {DescriptionRounded, InsertPhotoRounded, PictureAsPdfRounded} from "@material-ui/icons";
import React, {useCallback, useContext, useEffect, useState} from "react";
import Button from "../../Components/Button";
import Selector from "../../Components/Selector";
import CancelIcon from "@material-ui/icons/Cancel";
import {filters} from "../../secret-client.json";
import {generateString} from "../../Utils/string.utils";
import firebase from "firebase";
import UserContext from "../../Context/user.context";
import {useHistory} from "react-router-dom";
import BackdropContext from "../../Context/backdrop.context";
import Dropzone from "../../Components/Form/Dropzone";

var valueSexFilter = filters.sexes
var valueSeanceFilter = filters.seances
var valueNiveauFilter = filters.niveaux
var valueTagFilter = filters.tags
var valueObjectifFilter = filters.objectifs

const CreationProgrammePage = () => {

	const user = useContext(UserContext)
	const {setBackdrop} = useContext(BackdropContext)
	const theme = useTheme();

	const history = useHistory();

	const [isModification, setIsModification] = useState(false);
	const [urlParameters, setUrlParameters] = useState({});
	const [dialogOpen, setDialogOpen] = useState(false);

	const [name, setName] = useState("");
	const [sex, setSex] = useState("");
	const [nbSeance, setNbSeance] = useState("");
	const [level, setLevel] = useState("");
	const [tags, setTags] = useState([]);
	const [objective, setObjective] = useState("");
	const [description, setDescription] = useState("");
	const [price, setPrice] = useState(19.99);

	const [imageUrl, setImageUrl] = useState("");
	const [imageFirebasePath, setImageFirebasePath] = useState("");
	const [imageFile, setImageFile] = useState(undefined);

	const [contentUrl, setContentUrl] = useState("");
	const [contentFirebasePath, setContentFirebasePath] = useState("");
	const [contentFile, setContentFile] = useState(undefined);

	const useStyles = makeStyles((theme) => ({
		inputContainer : {
			display : "flex",
			flexWrap : "wrap",
			alignItems : "center",
			columnGap : "15px",
			padding : "15px",
			"& p" : {
				fontWeight : 600
			},
			"& input" : {
				height : "40%",
				borderRadius : "500px",
				padding : "5px 15px",
				"&[type=number]" : {
					textAlign : "right",
					minWidth : "80px"
				},
				"&[type=text]" : {
					minWidth : "250px"
				}
			},
			"& textarea" : {
				height : "250px",
				width : "100%",
				borderRadius : "25px",
				padding : "15px 15px",
				resize : "none"
			}
		},
		selectorContainer : {
			display : "flex",
			flexWrap : "wrap",
		},
		seeFileBtn : {
			display : "block",
			width : "calc(100% - 20px)",
			textAlign : "center",
			padding : "10px 0px",
			margin : "0px 10px",
			borderRadius : "200px",
			background : theme.palette.secondary.main,
			textDecoration : "none",
			color : theme.palette.text.dark,
			fontWeight : 600
		},
		saveButton : {
			width : "50%",
			marginLeft : "25%",
			marginTop : "25px",
			padding : "10px 0px",
		},
		deleteButton : {
			width : "50%",
			marginLeft : "25%",
			marginTop : "25px",
			padding : "10px 0px"
		},
	}))
	const classes = useStyles();

	useEffect(() => {
		var urlParameters = {}
		history.location.search.replace("?", "").split("&").forEach(param => {
			let parts = param.split("=")
			urlParameters[parts[0]] = parts[1]
		})
		setUrlParameters(urlParameters)
		//console.log("urlParameters", urlParameters)

		if(urlParameters.programId){
			setBackdrop(true)
			firebase.firestore().collection("Programmes").doc(urlParameters.programId).get().then(async doc => {
				if(!doc.exists) {
					//console.log("Le document n'existe pas")
					setBackdrop(false)
					return
				}

				var docData = doc.data()

				if(docData.coachUserId !== user.userId) {
					history.push("mes-programmes")
				}

				setIsModification(true)

				setSex(docData.sex)
				setObjective(docData.objective)
				setName(docData.name)
				setLevel(docData.level)
				setNbSeance(docData.nbSeance)
				setPrice(docData.price)
				setTags(docData.tags)
				setDescription(docData.description)

				setContentUrl(docData.downloadLink)
				setContentFirebasePath(docData.downloadStorageRef)
				setImageUrl(docData.imageLink)
				setImageFirebasePath(docData.imageStorageRef)

				setBackdrop(false)

			}).catch(e => {
				//console.log("Error", e)
				alert(e)
				setBackdrop(false)
			})
		}
	}, [])

	const handleImageChange = (url, file) => {
		setImageUrl(url);
		setImageFile(file)
	}

	const handleContentChange = (url, file) => {
		setContentUrl(url);
		setContentFile(file)
	}
	
	
	const handleSave = async () => {

		setBackdrop(true)
		try{
			const programId = isModification && urlParameters.programId ? urlParameters.programId : `Program-${name.replace(/ /g, "-").normalize()}-${generateString(4)}`;

			let refStorage = firebase.storage().ref();
			
			var data = {
				coachUserId : user.userId,
				description : description,
				level : level,
				name : name,
				nbSeance : nbSeance,
				objective : objective,
				price : parseFloat(price),
				programId : programId,
				sex : sex,
				tags : tags,
				nbVente : 0,
				reviews : []
			}

			if(imageFile){

				if(imageFirebasePath){
					refStorage.child(imageFirebasePath).delete()
				}

				let refImageFile = refStorage.child("Programmes/" + programId + "/" + imageFile.name)

				await refImageFile.put(imageFile)

				const imageStorageRef = refImageFile.fullPath

				const imageLink = await refImageFile.getDownloadURL()

				data = {
					...data,
					imageLink : imageLink,
					imageStorageRef : imageStorageRef,
				}
			}

			if(contentFile){

				if(contentFirebasePath){
					refStorage.child(contentFirebasePath).delete()
				}

				let refContentFile = refStorage.child("Programmes/" + programId + "/" + contentFile.name)

				await refContentFile.put(contentFile)

				const downloadStorageRef = refContentFile.fullPath

				const downloadLink = await refContentFile.getDownloadURL()

				data = {
					...data,
					downloadLink : downloadLink,
					downloadStorageRef : downloadStorageRef,
				}
			}

			//console.log(data)

			await firebase.firestore().collection("Programmes").doc(programId).set(data, {merge : true})

			history.push("/mes-programmes")
		}catch(e){
			//console.log("Erreur dans la création du programme : ", e)
		}finally {
			setBackdrop(false)
		}
	}

	const handleDelete = async () => {
		const programId = urlParameters.programId

		const doc = await firebase.firestore().collection("Programmes").doc(programId).get()
		let refStorage = firebase.storage().ref();
		let refContentFile = refStorage.child(doc.data().downloadStorageRef)
		let refImageFile = refStorage.child(doc.data().imageStorageRef)

		await refContentFile.delete()
		await refImageFile.delete()

		await firebase.firestore().collection("Programmes").doc(programId).delete()

		history.push("/mes-programmes")
	}

	return (
		<Layout column horizontalCenter>
			<h2>{isModification ? "Modification" : "Ajout"} d'un programme</h2>
			<Grid container>
				<Grid item xs={12} className={classes.inputContainer}>
					<p>Nom : </p>
					<input
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} className={classes.selectorContainer}>
					<Selector name={"Sexe"} value={sex} onChange={setSex} values={valueSexFilter}/>
					<Selector name={"Seance"} value={nbSeance} onChange={setNbSeance} values={valueSeanceFilter}/>
					<Selector name={"Niveau"} value={level} onChange={setLevel} values={valueNiveauFilter}/>
					<Selector name={"Tags"} value={tags} onChange={setTags} values={valueTagFilter} multiple/>
					<Selector name={"Objectif"} value={objective} onChange={setObjective} values={valueObjectifFilter}/>
				</Grid>
				<Grid item xs={12} className={classes.inputContainer}>
					<p style={{width : "100%"}}>Description : </p>
					<textarea
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} className={classes.inputContainer}>
					<p>Prix : </p>
					<input
						type="number"
						step={0.1}
						value={price}
						onChange={(e) => setPrice(e.target.value)}
					/>
				</Grid>
				<Grid item xs={12} md={6} style={{paddingTop : isModification ? "41.6px" : "0px"}}>
					<Dropzone
						callbackImageChange={handleImageChange}
						startFileUrl={imageUrl}
						startFileName={imageFile ? imageFile.name : null}
						icon={(<InsertPhotoRounded/>)}
						text={"Cliquez pour choisir l'image de votre programme"}
						accept={'image/*'}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					{isModification && (
						<a className={classes.seeFileBtn} target="_blank" href={contentUrl}>Voir le fichier</a>
					)}
					<Dropzone
						callbackImageChange={handleContentChange}
						startFileUrl={contentUrl}
						startFileName={contentFile ? contentFile.name : null}
						icon={(<DescriptionRounded/>)}
						text={"Cliquez pour choisir le fichier à télécharger"}
						accept={''}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button
						className={classes.saveButton}
						onClick={handleSave}
						disabled={!(
							name && name !== "" &&
							description && description !== "" &&
							sex && sex !== "" &&
							tags && tags.length >= 1 &&
							nbSeance && nbSeance !== "" &&
							level && level !== "" &&
							objective && objective !== ""
						)}
					>
						Sauvegarder
					</Button>
				</Grid>
				{isModification && (
					<Grid item xs={12}>
						<Button
							color={theme.palette.danger.main}
							className={classes.deleteButton}
							onClick={() => setDialogOpen(true)}
						>
							Supprimer le programme
						</Button>
						<Dialog open={dialogOpen}>
							<DialogTitle>Etes vous sûr de vouloir supprimer ce programme ?</DialogTitle>
							<DialogContent>Vous ne pourrez pas revenir en arrière</DialogContent>
							<DialogActions>
								<Button autoFocus onClick={() => setDialogOpen(false)} color="secondary">
									Annuler
								</Button>
								<Button onClick={() => handleDelete()} color="secondary">
									Supprimer
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				)}
			</Grid>
		</Layout>
	)
}

export default CreationProgrammePage