import firebase from "../Api/firebase.api";

const get = (productId) => (
	firebase.firestore()
		.collection("Products")
		.doc(productId)
		.get()
		.then(doc => doc.data())
)

export {
	get
}
//"Products"