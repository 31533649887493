import React, {useContext, useEffect, useState} from "react";
import UserContext from "../../Context/user.context";
import Button from "../../Components/Button";
import {makeStyles} from "@material-ui/core";
import Layout from "../../Components/Layout";
import ProgramsList from "../../Components/Programs/ProgramsList";
import firebase from "firebase";
import {useHistory} from "react-router-dom";

const MesProgrammesPage = () => {
	
	const [ownedPrograms, setOwnedPrograms] = useState([])
	const [buyedPrograms, setBuyedPrograms] = useState([])
	
	var user = useContext(UserContext)
	
	const useStyle = makeStyles((theme) => ({
	
	}))
	const classes = useStyle()
	
	useEffect(() => {
		const prepare = async () => {
			
			setOwnedPrograms((await firebase.firestore().collection("Programmes").where("coachUserId", "==", user.userId).get()).docs.map(doc => doc.data()) ?? [])
			
			var buyedProgramsId = user.programs ?? []
			setBuyedPrograms((await Promise.all(
				buyedProgramsId.map(async (programId) => {
					try {
						return (await firebase.firestore().collection("Programmes").doc(programId).get()).data()
					} catch(e) {
						return null
					}
				})
			)).filter(program => program))
		}
		prepare();
	}, [])
	
    return (
    	<Layout column horizontalCenter>
			<h2>Mes programmes</h2>
			{(user && (user.type === "coach" || user.type === "admin") && !user.allowAccess.programmes) && (<p style={{textAlign : "center"}}>Vous n'avez pas le droit de publier des programmes.<br/>Si vous venez de créer votre compte, la vérification de celui ci peut prendre jusqu'à 48h.</p>)}
			{
				(user && (user.type === "coach" || user.type === "admin") && user.allowAccess.programmes) && (<CoachPart ownedPrograms={ownedPrograms}/>)
			}
			<Layout width={"100%"}>
				<ProgramsList programsList={buyedPrograms}/>
			</Layout>
		</Layout>
    );
}

const CoachPart = ({ownedPrograms}) => {
	
	const useStyle = makeStyles((theme) => ({
		addProgramBtn : {
			padding : "15px 35px",
			width : "fit-content",
		},
		addProgramBtnContainer : {
			display : "flex",
			justifyContent : "center"
		},
		coachPart : {
			width : "100%"
		},
	}))
	const classes = useStyle()
	
	const history = useHistory()
	
	return (
		<div className={classes.coachPart}>
			<div className={classes.addProgramBtnContainer}>
				<Button className={classes.addProgramBtn} onClick={() => history.push("/creation-programme")}>
					Ajouter un programme
				</Button>
			</div>
			<h3>Mes créations</h3>
			<Layout>
				{
					ownedPrograms && ownedPrograms.length > 0 ? (
						<ProgramsList programsList={ownedPrograms}/>
					) : (
						<p>Vous n'avez pas encore créé de programme.</p>
					)
				}
			</Layout>
			<h3>Mes achats</h3>
		</div>
	)
}

export default MesProgrammesPage;
