import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import {Button} from "@material-ui/core";

export default function Search({setTextFilter, textFilter, setModalDisplay, modalDisplay, ...rest}) {

    const useStyles = makeStyles((theme) => ({
        searchComponent: {
            width: "100%",
            margin: "0 10px",
            color: theme.palette.text.light,
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
		    [theme.breakpoints.down('sm')]: {justifyContent: "space-evenly"},
        },
        inputSearch: {
            width: "80%",
            height: "30px",
            borderRadius: "10px",
            backgroundColor: "#31324d",
            color: "white",
            borderStyle: "none",
            paddingLeft : "50px",
            marginRight:"10%",
        },
        search: {
            width: "80%",
            display: "flex",
            alignItems: "center",
            position:"relative",
		    [theme.breakpoints.down('sm')]: {width: "60%"},
        },
        iconSearch: {
            position: "absolute",
            width: "50px",
        },
        buttonSearch: {
            borderRadius: "50px",
            width : "20%",
		    [theme.breakpoints.down('sm')]: {width: "30%"},
            color: "white",
        }
    }));


    const classes = useStyles();
    return (
        <div className={classes.searchComponent} {...rest}>
            <div className={classes.search}>
                <SearchIcon className={classes.iconSearch} />
                <input
                    className={classes.inputSearch}
                    type="text"
                    id="search-bar"
                    placeholder="Rechercher"
                    value={textFilter}
                    onChange={(event)=>{setTextFilter(event.target.value)}}
                />
            </div>

            <Button color="secondary" variant="contained" className={classes.buttonSearch} onClick={() => {setModalDisplay(!modalDisplay) }}>
                <b>Filtres</b>
            </Button>
        </div>
    );
}
