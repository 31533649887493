import {makeStyles} from "@material-ui/core";
import React, {useEffect} from "react";


const useStyles = makeStyles(theme => ({
	input: {
		backgroundColor: "#d0d0d0",
		borderRadius: "1rem",
		padding: "0.5rem 0.15rem",
		textAlign: "center"
	},
}));

const InputTextField = ({icon: IconComponent, placeholder, type, value, handleChange, className, ...rest}) => {
	const classes = useStyles();

	return(
		<input
			{...rest}
			className={classes.input + " " + className}
			onChange={(event)=>{handleChange(event.target.value)}}
			type={type}
			placeholder={placeholder}
			value={value}
		/>
	)
}

export default InputTextField