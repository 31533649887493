import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";

import {
	makeStyles,
} from "@material-ui/core";


const Dropzone = ({callbackImageChange, startFileUrl, startFileName, icon, text, accept}) => {

	const [selectedFileUrl, setSelectedFileUrl] = useState(startFileUrl ? startFileUrl : null);
	const [selectedFileName, setSelectedFileName] = useState(startFileName ? startFileName : null);

	const onDrop = useCallback(async (acceptedFiles) => {
		try{
			let file = acceptedFiles[0]
			let url = URL.createObjectURL(file);
			setSelectedFileUrl(url)
			setSelectedFileName(file.name)
			callbackImageChange(url, file)
		}catch(e){
			//console.log(e);
		}
	}, [callbackImageChange]);

	const {getRootProps, getInputProps} = useDropzone({
		accept: accept,
		onDrop: onDrop
	});

	const useStyles = makeStyles((theme) => ({
		dropzone : {
			height : "150px",
			border : "2px dashed" + theme.palette.text.light,
			borderRadius : "15px",
			padding : "15px",
			margin : "10px",
			display : "flex",
			flexDirection : "column",
			alignItems : "center",
			justifyContent : "space-between",
			cursor : "pointer",
			"& svg" : {
				width : selectedFileUrl ? "10%" : "30%",
				height : selectedFileUrl ? "30%" : "60%",
				transition : "all 3000ms linear"
			},
			"& p" : {
				textAlign : "center"
			}
		},
		img : {
			padding : "15px",
			display: 'block',
			maxWidth : "208px",
			maxHeight : "78px"
		}
	}))
	const classes = useStyles();


	useEffect(() => {
		setSelectedFileUrl(startFileUrl)
		setSelectedFileName(startFileName)
	}, [startFileUrl, startFileName])

	return (
		<div {...getRootProps()} className={classes.dropzone}>
			<input {...getInputProps()}/>
			{selectedFileUrl ? (
				<>
					{accept.includes("image") ? (
						<img alt="Glissez ici" src={selectedFileUrl} className={classes.img}/>
					) : (
						<>{icon}{selectedFileName}</>
					)}
				</>
			) : icon}
			<p>{text}</p>
		</div>
	)
}


export default Dropzone