import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "react-router-dom";
import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	makeStyles, MenuItem,
	Radio,
	RadioGroup,
	Select
} from "@material-ui/core";
import {isWidthUp} from '@material-ui/core/withWidth';

import InputTextField from "../InputTextField";

import * as UserModel from '../../Model/user.model'
import useWidth from "../../Api/width.hook";
import BackdropContext from "../../Context/backdrop.context";

const useStyles = makeStyles(theme => ({
	formGrid: {
		[theme.breakpoints.down('sm')]: {marginTop: "3vh"},
		textAlign: "center",
	},
	title : {
		[theme.breakpoints.down('sm')]: {marginTop: "4vh"},
		[theme.breakpoints.up('sm')]: {
			marginTop: "0rem",
			color: "white",
			textAlign: "center"
		}
	}
}));

const countries = [
	{"name": "France", "code": "FR", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "United States", "code": "US"},
	{"name": "Afghanistan", "code": "AF"},
	{"name": "Åland Islands", "code": "AX"},
	{"name": "Albania", "code": "AL"},
	{"name": "Algeria", "code": "DZ"},
	{"name": "American Samoa", "code": "AS"},
	{"name": "Andorra", "code": "AD"},
	{"name": "Angola", "code": "AO"},
	{"name": "Anguilla", "code": "AI"},
	{"name": "Antarctica", "code": "AQ"},
	{"name": "Antigua and Barbuda", "code": "AG"},
	{"name": "Argentina", "code": "AR"},
	{"name": "Armenia", "code": "AM"},
	{"name": "Aruba", "code": "AW"},
	{"name": "Australia", "code": "AU"},
	{"name": "Austria", "code": "AT", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Azerbaijan", "code": "AZ"},
	{"name": "Bahamas", "code": "BS"},
	{"name": "Bahrain", "code": "BH"},
	{"name": "Bangladesh", "code": "BD"},
	{"name": "Barbados", "code": "BB"},
	{"name": "Belarus", "code": "BY"},
	{"name": "Belgium", "code": "BE", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Belize", "code": "BZ"},
	{"name": "Benin", "code": "BJ"},
	{"name": "Bermuda", "code": "BM"},
	{"name": "Bhutan", "code": "BT"},
	{"name": "Bolivia", "code": "BO"},
	{"name": "Bosnia and Herzegovina", "code": "BA"},
	{"name": "Botswana", "code": "BW"},
	{"name": "Bouvet Island", "code": "BV"},
	{"name": "Brazil", "code": "BR"},
	{"name": "British Indian Ocean Territory", "code": "IO"},
	{"name": "Brunei Darussalam", "code": "BN"},
	{"name": "Bulgaria", "code": "BG", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Burkina Faso", "code": "BF"},
	{"name": "Burundi", "code": "BI"},
	{"name": "Cambodia", "code": "KH"},
	{"name": "Cameroon", "code": "CM"},
	{"name": "Canada", "code": "CA"},
	{"name": "Cape Verde", "code": "CV"},
	{"name": "Cayman Islands", "code": "KY"},
	{"name": "Central African Republic", "code": "CF"},
	{"name": "Chad", "code": "TD"},
	{"name": "Chile", "code": "CL"},
	{"name": "China", "code": "CN"},
	{"name": "Christmas Island", "code": "CX"},
	{"name": "Cocos (Keeling) Islands", "code": "CC"},
	{"name": "Colombia", "code": "CO"},
	{"name": "Comoros", "code": "KM"},
	{"name": "Congo", "code": "CG"},
	{"name": "Congo, The Democratic Republic of the", "code": "CD"},
	{"name": "Cook Islands", "code": "CK"},
	{"name": "Costa Rica", "code": "CR"},
	{"name": "Cote D'Ivoire", "code": "CI"},
	{"name": "Croatia", "code": "HR", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Cuba", "code": "CU"},
	{"name": "Cyprus", "code": "CY", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Czech Republic", "code": "CZ", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Denmark", "code": "DK", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Djibouti", "code": "DJ"},
	{"name": "Dominica", "code": "DM"},
	{"name": "Dominican Republic", "code": "DO"},
	{"name": "Ecuador", "code": "EC"},
	{"name": "Egypt", "code": "EG"},
	{"name": "El Salvador", "code": "SV"},
	{"name": "Equatorial Guinea", "code": "GQ"},
	{"name": "Eritrea", "code": "ER"},
	{"name": "Estonia", "code": "EE", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Ethiopia", "code": "ET"},
	{"name": "Falkland Islands (Malvinas)", "code": "FK"},
	{"name": "Faroe Islands", "code": "FO"},
	{"name": "Fiji", "code": "FJ"},
	{"name": "Finland", "code": "FI", "isEU" : true, "inclusive" : true, "vatPercentage": 24},
	{"name": "French Guiana", "code": "GF"},
	{"name": "French Polynesia", "code": "PF"},
	{"name": "French Southern Territories", "code": "TF"},
	{"name": "Gabon", "code": "GA"},
	{"name": "Gambia", "code": "GM"},
	{"name": "Georgia", "code": "GE"},
	{"name": "Germany", "code": "DE", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Ghana", "code": "GH"},
	{"name": "Gibraltar", "code": "GI"},
	{"name": "Greece", "code": "GR", "isEU" : true, "inclusive" : true, "vatPercentage": 24},
	{"name": "Greenland", "code": "GL"},
	{"name": "Grenada", "code": "GD"},
	{"name": "Guadeloupe", "code": "GP"},
	{"name": "Guam", "code": "GU"},
	{"name": "Guatemala", "code": "GT"},
	{"name": "Guernsey", "code": "GG"},
	{"name": "Guinea", "code": "GN"},
	{"name": "Guinea-Bissau", "code": "GW"},
	{"name": "Guyana", "code": "GY"},
	{"name": "Haiti", "code": "HT"},
	{"name": "Heard Island and Mcdonald Islands", "code": "HM"},
	{"name": "Holy See (Vatican City State)", "code": "VA"},
	{"name": "Honduras", "code": "HN"},
	{"name": "Hong Kong", "code": "HK"},
	{"name": "Hungary", "code": "HU", "isEU" : true, "inclusive" : true, "vatPercentage": 27},
	{"name": "Iceland", "code": "IS"},
	{"name": "India", "code": "IN"},
	{"name": "Indonesia", "code": "ID"},
	{"name": "Iran, Islamic Republic Of", "code": "IR"},
	{"name": "Iraq", "code": "IQ"},
	{"name": "Ireland", "code": "IE", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Isle of Man", "code": "IM"},
	{"name": "Israel", "code": "IL"},
	{"name": "Italy", "code": "IT", "isEU" : true, "inclusive" : true, "vatPercentage": 22},
	{"name": "Jamaica", "code": "JM"},
	{"name": "Japan", "code": "JP"},
	{"name": "Jersey", "code": "JE"},
	{"name": "Jordan", "code": "JO"},
	{"name": "Kazakhstan", "code": "KZ"},
	{"name": "Kenya", "code": "KE"},
	{"name": "Kiribati", "code": "KI"},
	{"name": "Korea, Democratic People'S Republic of", "code": "KP"},
	{"name": "Korea, Republic of", "code": "KR"},
	{"name": "Kuwait", "code": "KW"},
	{"name": "Kyrgyzstan", "code": "KG"},
	{"name": "Lao People'S Democratic Republic", "code": "LA"},
	{"name": "Latvia", "code": "LV", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Lebanon", "code": "LB"},
	{"name": "Lesotho", "code": "LS"},
	{"name": "Liberia", "code": "LR"},
	{"name": "Libyan Arab Jamahiriya", "code": "LY"},
	{"name": "Liechtenstein", "code": "LI"},
	{"name": "Lithuania", "code": "LT", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Luxembourg", "code": "LU", "isEU" : true, "inclusive" : true, "vatPercentage": 17},
	{"name": "Macao", "code": "MO"},
	{"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
	{"name": "Madagascar", "code": "MG"},
	{"name": "Malawi", "code": "MW"},
	{"name": "Malaysia", "code": "MY"},
	{"name": "Maldives", "code": "MV"},
	{"name": "Mali", "code": "ML"},
	{"name": "Malta", "code": "MT", "isEU" : true, "inclusive" : true, "vatPercentage": 18},
	{"name": "Marshall Islands", "code": "MH"},
	{"name": "Martinique", "code": "MQ"},
	{"name": "Mauritania", "code": "MR"},
	{"name": "Mauritius", "code": "MU"},
	{"name": "Mayotte", "code": "YT"},
	{"name": "Mexico", "code": "MX"},
	{"name": "Micronesia, Federated States of", "code": "FM"},
	{"name": "Moldova, Republic of", "code": "MD"},
	{"name": "Monaco", "code": "MC"},
	{"name": "Mongolia", "code": "MN"},
	{"name": "Montserrat", "code": "MS"},
	{"name": "Morocco", "code": "MA"},
	{"name": "Mozambique", "code": "MZ"},
	{"name": "Myanmar", "code": "MM"},
	{"name": "Namibia", "code": "NA"},
	{"name": "Nauru", "code": "NR"},
	{"name": "Nepal", "code": "NP"},
	{"name": "Netherlands", "code": "NL", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Netherlands Antilles", "code": "AN"},
	{"name": "New Caledonia", "code": "NC"},
	{"name": "New Zealand", "code": "NZ"},
	{"name": "Nicaragua", "code": "NI"},
	{"name": "Niger", "code": "NE"},
	{"name": "Nigeria", "code": "NG"},
	{"name": "Niue", "code": "NU"},
	{"name": "Norfolk Island", "code": "NF"},
	{"name": "Northern Mariana Islands", "code": "MP"},
	{"name": "Norway", "code": "NO"},
	{"name": "Oman", "code": "OM"},
	{"name": "Pakistan", "code": "PK"},
	{"name": "Palau", "code": "PW"},
	{"name": "Palestinian Territory, Occupied", "code": "PS"},
	{"name": "Panama", "code": "PA"},
	{"name": "Papua New Guinea", "code": "PG"},
	{"name": "Paraguay", "code": "PY"},
	{"name": "Peru", "code": "PE"},
	{"name": "Philippines", "code": "PH"},
	{"name": "Pitcairn", "code": "PN"},
	{"name": "Poland", "code": "PL", "isEU" : true, "inclusive" : true, "vatPercentage": 23},
	{"name": "Portugal", "code": "PT", "isEU" : true, "inclusive" : true, "vatPercentage": 19},
	{"name": "Puerto Rico", "code": "PR"},
	{"name": "Qatar", "code": "QA"},
	{"name": "Reunion", "code": "RE"},
	{"name": "Romania", "code": "RO"},
	{"name": "Russian Federation", "code": "RU"},
	{"name": "RWANDA", "code": "RW"},
	{"name": "Saint Helena", "code": "SH"},
	{"name": "Saint Kitts and Nevis", "code": "KN"},
	{"name": "Saint Lucia", "code": "LC"},
	{"name": "Saint Pierre and Miquelon", "code": "PM"},
	{"name": "Saint Vincent and the Grenadines", "code": "VC"},
	{"name": "Samoa", "code": "WS"},
	{"name": "San Marino", "code": "SM"},
	{"name": "Sao Tome and Principe", "code": "ST"},
	{"name": "Saudi Arabia", "code": "SA"},
	{"name": "Senegal", "code": "SN"},
	{"name": "Serbia and Montenegro", "code": "CS"},
	{"name": "Seychelles", "code": "SC"},
	{"name": "Sierra Leone", "code": "SL"},
	{"name": "Singapore", "code": "SG"},
	{"name": "Slovakia", "code": "SK", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "Slovenia", "code": "SI", "isEU" : true, "inclusive" : true, "vatPercentage": 22},
	{"name": "Solomon Islands", "code": "SB"},
	{"name": "Somalia", "code": "SO"},
	{"name": "South Africa", "code": "ZA"},
	{"name": "South Georgia and the South Sandwich Islands", "code": "GS"},
	{"name": "Spain", "code": "ES", "isEU" : true, "inclusive" : true, "vatPercentage": 21},
	{"name": "Sri Lanka", "code": "LK"},
	{"name": "Sudan", "code": "SD"},
	{"name": "Suriname", "code": "SR"},
	{"name": "Svalbard and Jan Mayen", "code": "SJ"},
	{"name": "Swaziland", "code": "SZ"},
	{"name": "Sweden", "code": "SE", "isEU" : true, "inclusive" : true, "vatPercentage": 25},
	{"name": "Switzerland", "code": "CH"},
	{"name": "Syrian Arab Republic", "code": "SY"},
	{"name": "Taiwan, Province of China", "code": "TW"},
	{"name": "Tajikistan", "code": "TJ"},
	{"name": "Tanzania, United Republic of", "code": "TZ"},
	{"name": "Thailand", "code": "TH"},
	{"name": "Timor-Leste", "code": "TL"},
	{"name": "Togo", "code": "TG"},
	{"name": "Tokelau", "code": "TK"},
	{"name": "Tonga", "code": "TO"},
	{"name": "Trinidad and Tobago", "code": "TT"},
	{"name": "Tunisia", "code": "TN"},
	{"name": "Turkey", "code": "TR"},
	{"name": "Turkmenistan", "code": "TM"},
	{"name": "Turks and Caicos Islands", "code": "TC"},
	{"name": "Tuvalu", "code": "TV"},
	{"name": "Uganda", "code": "UG"},
	{"name": "Ukraine", "code": "UA"},
	{"name": "United Arab Emirates", "code": "AE"},
	{"name": "United Kingdom", "code": "GB", "isEU" : true, "inclusive" : true, "vatPercentage": 20},
	{"name": "United States Minor Outlying Islands", "code": "UM"},
	{"name": "Uruguay", "code": "UY"},
	{"name": "Uzbekistan", "code": "UZ"},
	{"name": "Vanuatu", "code": "VU"},
	{"name": "Venezuela", "code": "VE"},
	{"name": "Viet Nam", "code": "VN"},
	{"name": "Virgin Islands, British", "code": "VG"},
	{"name": "Virgin Islands, U.S.", "code": "VI"},
	{"name": "Wallis and Futuna", "code": "WF"},
	{"name": "Western Sahara", "code": "EH"},
	{"name": "Yemen", "code": "YE"},
	{"name": "Zambia", "code": "ZM"},
	{"name": "Zimbabwe", "code": "ZW"}
]
const HOME_COUNTRY_CODE = 'FR';


const RegisterForm = ({handleClose}) => {
	const [step, setStep] = useState("account")
	const [user, setUser] = useState({});
	
	const [error, setError] = useState(null);

	const {setBackdrop} = useContext(BackdropContext)
	const history = useHistory();
	const classes = useStyles();
	const width = useWidth();

	const handleNextAccount = (userAccount) => {
		try{
			setUser({...user, ...userAccount})
			setStep("location")
		}catch(e) {
			setError(e.message)
		}
	}

	const handleNextLocation = (userLocation) => {
		try{
			setUser({...user, ...userLocation})
			setStep("type")
		}catch(e) {
			setError(e.message)
		}
	}

	const handleNextType = (userType) => {
		setBackdrop(true)

		let userData = {...user, ...userType};
		if(userData.siret){
			userData.type = "coach"
			userData.allowAccess = {
				programmes : false,
				blog : false
			}
		}else{
			userData.type = "customer"
		}
		delete userData.password;
		
		let tax_exempt = "none"
		let isEUCustomer = false
		if(countries.filter(c => c.isEU).map(c => c.code).includes(userData.country)){
			isEUCustomer = true
			if(userData.vat && userData.country !== HOME_COUNTRY_CODE){
				tax_exempt = "reverse";
			}
		}else {
			tax_exempt = "exempt";
		}
		userData = {...userData, taxExempt : tax_exempt, creationTime: new Date()}
		
		UserModel.create(
			user.email,
			user.password,
			userData
		).then(()=>{
			//console.log(history.location.pathname);
			if(history.location.pathname !== "/paiement")
				handleClose && handleClose()
		}).catch((e)=>{
			setError(e.message)
		}).finally(() => {
			setBackdrop(false)
		})
	}

	return(
		<section id="register-form">
			<h3 className={classes.title}>
				Inscription
			</h3>

			<Grid
				container
				justify="center"
				spacing={!isWidthUp('sm', width) ? 6 : 3}
				direction={"column"}
				className={classes.formGrid}
			>
				{step === "account" ? (
					<AccountStep handleNext={handleNextAccount}/>
				) : step === "location" ? (
					<LocationStep handleNext={handleNextLocation}/>
				) : (
					<TypeStep handleNext={handleNextType}/>
				)}
			</Grid>
			
			{error && (<p style={{color : "red"}}>error</p>)}
		</section>
	)
}

const AccountStep = ({handleNext}) => {
	const [fullname, setFullname] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")

	const [disabled, setDisabled] = useState(true)
	const width = useWidth();

	useEffect(()=>{
		setDisabled(email === "" || password === "" || fullname === "")
	}, [email, password, fullname])

	const handleClick = () => {
		handleNext({
			email: email,
			fullname: fullname,
			password: password,
		})
	}

	return(
		<React.Fragment>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setFullname}
					type="text"
					placeholder="Votre nom complet"
					value={fullname}
				/>
			</Grid>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setEmail}
					type="email"
					placeholder="Votre adresse email"
					value={email}
				/>
			</Grid>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setPassword}
					type="password"
					placeholder="Votre mot de passe"
					value={password}
				/>
			</Grid>

			<Grid item>
				<Button
					color="secondary"
					variant="contained"
					disabled={disabled}
					style={{
						width: !isWidthUp('sm', width) ? "50vw" : "13vw",
						borderRadius: "1rem"
					}}
					onClick={handleClick}
				>
					<b>Suivant</b>
				</Button>
			</Grid>
		</React.Fragment>
	)
}

const LocationStep = ({handleNext}) => {
	const [address1, setAddress1] = useState("")
	const [address2, setAddress2] = useState("")

	const [postalCode, setPostalCode] = useState("")
	const [city, setCity] = useState("")
	const [country, setCountry] = useState("FR")

	const [disabled, setDisabled] = useState(true)

	const width = useWidth();

	useEffect(()=>{
		setDisabled(
			address1 === "" || postalCode === "" || city === "" || country === ""
		)
	}, [address1, postalCode, city, country])

	const handleClick = () => {
		handleNext({
			location: {
				address1: address1,
				address2: address2,
				postalCode: postalCode,
				city: city,
				country: country
			}
		})
	}

	return(
		<React.Fragment>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setAddress1}
					type="text"
					placeholder="Votre adresse ligne 1"
					value={address1}
				/>
			</Grid>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setAddress2}
					type="text"
					placeholder="Votre adresse ligne 2"
					value={address2}
				/>
			</Grid>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setPostalCode}
					type="number"
					placeholder="Votre code postal"
					value={postalCode}
				/>
			</Grid>
			<Grid item>
				<InputTextField
					style={{
						width: !isWidthUp('sm', width) ? "60vw" : "13vw"
					}}
					handleChange={setCity}
					type="text"
					placeholder="Votre ville"
					value={city}
				/>
			</Grid>
			<Grid item>
				<Select
					labelId="demo-simple-select-filled-label"
					id="demo-simple-select-filled"
					style={{width: !isWidthUp('sm', width) ? "60vw" : "13vw", background : "#d0d0d0", borderRadius : "50px"}}
					value={country}
					placeholder="Votre pays"
					onChange={(e) => setCountry(e.target.value)}
				>
					{
						countries.map((countryData, index) => (
							<MenuItem value={countryData.code} key={index}>{countryData.name}</MenuItem>
						))
					}
				</Select>
			</Grid>

			<Grid item>
				<Button
					color="secondary"
					variant="contained"
					disabled={disabled}
					style={{
						width: isWidthUp('sm', width) ? "10vw" : "50vw",
						borderRadius: "1rem"
					}}
					onClick={handleClick}
				>
					<b>Suivant</b>
				</Button>
			</Grid>
		</React.Fragment>
	)
}

const typeStepStyles = makeStyles(theme => ({
	root: {
		color: "rgba(255 255 255 / 54%)",
	}
}));
const TypeStep = ({handleNext}) => {
	const [type, setType] = useState(null)
	const [vat, setVat] = useState(null)
	const [siret, setSiret] = useState(null)

	const [disabled, setDisabled] = useState(true);
	const width = useWidth();
	const classes = typeStepStyles();

	useEffect(()=>{
		let cond = type === null

		if(type === "pro")
			cond = cond || siret === null;

		setDisabled(cond)
	}, [vat, type, siret])

	const handleClick = () => {
		if(type !== "pro")
			handleNext({})

		handleNext({vat: vat, siret : siret})
	}
	const handleChange = (event) => {
		setType(event.target.value);
	};

	return(
		<React.Fragment>
			<Grid item>
				<FormControl component="fieldset">
					<FormLabel component="legend" style={{color : "white"}}>Type de compte</FormLabel>
					<RadioGroup aria-label="type" name="type" value={type} onChange={handleChange}  style={{color : "white"}}>
						<FormControlLabel value="pro" control={<Radio className={classes.root} />} label="Coach" />
						<FormControlLabel value="individual" control={<Radio className={classes.root} />} label="Particulier" />
					</RadioGroup>
				</FormControl>
			</Grid>

			{type === "pro" && (
				<>
				<Grid item>
					<InputTextField
						style={{width: !isWidthUp('sm', width) ? "60vw" : "13vw"}}
						handleChange={setVat}
						type="text"
						placeholder="Votre numéro VAT"
						value={vat}
					/>
				</Grid>
				<Grid item>
					<InputTextField
						style={{width: !isWidthUp('sm', width) ? "60vw" : "13vw"}}
						handleChange={setSiret}
						type="text"
						placeholder="Votre numéro SIRET"
						value={siret}
					/>
				</Grid>
				</>
			)}

			<Grid item>
				<Button
					color="secondary"
					variant="contained"
					disabled={disabled}
					style={{
						width: isWidthUp('sm', width) ? "10vw" : "50vw",
						borderRadius: "1rem"
					}}
					onClick={handleClick}
				>
					<b>S'inscrire</b>
				</Button>
			</Grid>
		</React.Fragment>
	)
}

export default RegisterForm