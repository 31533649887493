import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import Layout from "../Components/Layout";
import SEO from "../Components/SEO";

import firebase from 'firebase/app'
import PostsList from "../Components/Blog/BlogPostsList";
import CoachesList from "../Components/Coach/CoachesList";
import SearchIcon from "@material-ui/icons/Search";

export default function NosCoachPage() {
	
    const useStyles = makeStyles((theme) => ({
        coachListComponent: {
            height : "100%",
            width : "100%",
            position : "relative",
        },
		inputSearch: {
			width: "80%",
			height: "30px",
			borderRadius: "10px",
			backgroundColor: "#31324d",
			color: "white",
			borderStyle: "none",
			paddingLeft : "50px",
			marginRight:"10%",
		},
		search: {
			width: "90%",
			marginLeft : "10%",
			display: "flex",
			alignItems: "center",
			position:"relative",
			[theme.breakpoints.down('sm')]: {width: "60%"},
		},
		iconSearch: {
			position: "absolute",
			width: "50px",
		},
    }));
    const classes = useStyles();
	
	const [coaches, setCoaches] = useState([])
	const [coachesDisplayed, setCoachesDisplayed] = useState([])
	const [textFilter, setTextFilter] = useState("")

    useEffect(() => {
		firebase.firestore().collection("Users").where("type", "!=", "customer").get().then((snapshot) => {
			setCoaches(snapshot.docs.map(doc => doc.data()))
		})
    }, []);
    
    useEffect(() => {
		setCoachesDisplayed(
			coaches.filter(coach => coach.fullname.toLowerCase().includes(textFilter.toLowerCase()))
		)
	}, [coaches, textFilter])

    return (
        <div className={classes.coachListComponent}>
            <Layout column horizontalCenter>
				<div className={classes.search}>
					<SearchIcon className={classes.iconSearch} />
					<input
						className={classes.inputSearch}
						type="text"
						id="search-bar"
						placeholder="Rechercher"
						value={textFilter}
						onChange={(event)=>{setTextFilter(event.target.value)}}
					/>
				</div>
				
				<Layout width={"100%"}>
					<CoachesList choachesList={coachesDisplayed}/>
				</Layout>
            </Layout>
        </div>
    );
}
