import {useEffect, useState, useContext} from "react";
import {useHistory, useParams} from "react-router-dom";
import StarRatings from 'react-star-ratings';

import {makeStyles} from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import {ShoppingCartRounded, CloudDownloadOutlined} from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SEO from "../Components/SEO";


import firebase from '../Api/firebase.api';

import Layout from "../Components/Layout";
import Button from "../Components/Button";
import UserContext from "../Context/user.context";

import * as ShoppingCartModel from "../Model/shopping-cart.model";
import BackdropContext from "../Context/backdrop.context";

const ProgramDetailPage = () => {
	
	const useStyles = makeStyles((theme) => ({
        ProgramDetailPageComponent: {  
            height : "100%",
            width : "100%",
            position : "relative",
			display: "flex",
			flexDirection:"column",
			alignItems:"center", 
			"& a": {
				textDecoration : "underline"
			},
			"& b" : {
            	color : theme.palette.secondary.main
			}
        },
		note: {
			display: "flex",
			color: theme.palette.secondary.main,
			alignItems: "center",
			margin: "5px 0",
			"& p": {
				color: theme.palette.text.light,
				margin: "0 10px",
			},
		},
		image: {
			width:"60%",
		},
    }));
    const classes = useStyles();

	const user = useContext(UserContext)
	const {setBackdrop} = useContext(BackdropContext)

	const history = useHistory()
	//const {programId} = useParams();

	const [programData, setProgramData] = useState(null)
	const [coachData, setCoachData] = useState(null)
	const [rating, setRating] = useState(0)
	
	const [Cta, setCta] = useState(<></>)
	
	useEffect(() => {
		const prepare = async () => {
			try{
				let data = (await firebase.firestore().collection("Programmes").doc(history.location.pathname.split("/")[2]).get()).data()
				setProgramData(data)

				if(data.reviews.length !== 0){
					let average = 0;
					data.reviews.forEach((review) => {
						average = average + review.review;
					})
					average = average / data.reviews.length;
					setRating(average)
				}

				//console.log("reviews" ,  data.reviews)
				let data2 = (await firebase.firestore().collection("Users").doc(data.coachUserId).get()).data()
				setCoachData(data2)
			}catch(e){
				//console.log(e)
				history.push("/nos-programmes")
			}
		}
		prepare();
	}, [history.location.pathname])
	
	useEffect(() => {
		//console.log("user", user)
		//console.log("programData", programData)
		//Affichage conditionnel du CTA
		if (user && programData) {
			if(user.programs && user.programs.includes(programData.programId)){ //S'il a acheté le programme
				setCta((
					<Button
						style={{
							padding : "10px 25px",
							borderRadius: "50px",
							maxWidth : "40%",
							width : "fit-content",
							color: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginBottom : "20px"
						}}
						onClick={() => window.open(programData.downloadLink,'_blank')}
					>
						<CloudDownloadOutlined style={{width:"15%", minWidth : "50px"}}/>
						Télécharger
					</Button>
				))
			}else if(coachData && user.userId === coachData.userId){ //S'il est le coach qui a créé le programme
				setCta((
					<Button
						style={{
							padding : "10px 25px",
							borderRadius: "50px",
							maxWidth : "40%",
							width : "fit-content",
							color: "white",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginBottom : "20px"
						}}
						onClick={() => history.push("/creation-programme?programId=" + programData.programId)}
					>
						<EditIcon style={{width:"15%", minWidth : "50px"}}/>
						Modifier
					</Button>
				))
			}else{ //Sinon (si c'est un potentiel client)
				ShoppingCartModel.get_content(user).then(cart => {
					try{
						//console.log("Cart", typeof cart, cart)
						if(cart && cart.filter(good => good.id === programData.programId).length > 0){ //Si déjà dans le panier
							setCta((
								<Button
									style={{
										padding : "10px 25px",
										borderRadius: "50px",
										maxWidth : "40%",
										width : "fit-content",
										color: "white",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginBottom : "20px"
									}}
									onClick={handleRemoveFromCart}
								>
									<ShoppingCartRounded style={{width:"15%", minWidth : "50px"}}/>
									Retirer du panier
								</Button>
							))
						}else{
							setCta((
								<Button
									style={{
										padding : "10px 25px",
										borderRadius: "50px",
										maxWidth : "40%",
										width : "fit-content",
										color: "white",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										marginBottom : "20px"
									}}
									onClick={handleAddToCart}
								>
									<ShoppingCartRounded style={{width:"15%", minWidth : "50px"}}/>
									Ajouter au panier
								</Button>
							))
						}
					}catch(e) {
						//console.log(e)
					}
				})
			}
		}
		
	}, [user, programData, coachData])


    const changeRating = async (newRating) => {
		setRating(newRating);
	    var updatedReview = [
	    	...programData.reviews.filter(review => review.userId !== user.userId), //Les reviews des autres
		    {review : newRating, userId : user.userId}
	    ]
		await firebase.firestore().collection("Programmes").doc(programData.programId).set({
			reviews : updatedReview
		}, {merge : true})

	    window.location.reload();
	}
	
	const handleAddToCart = async () => {
		//console.log("handleAddToCart")
		setBackdrop(true)
		
		await ShoppingCartModel.add(user, programData.programId, ShoppingCartModel.TYPES.programme)
		history.go(0)
		
		setBackdrop(false)
	}
	
	const handleRemoveFromCart = async () => {
		//console.log("handleRemoveFromCart")
		setBackdrop(true)
		
		await ShoppingCartModel.remove(user, programData.programId)
		history.go(0)
		
		setBackdrop(false)
	}

	let Stars;
    if (user && programData && user.programs && user.programs.includes(programData.programId)) {
		if(programData.reviews.filter(review => review.userId === user.userId).length > 0){  //S'il a acheté le programme && a déjà noté
			Stars = 
			<div className={classes.note}> 
				<StarRatings
					rating={rating}
					starRatedColor="yellow"
					changeRating={changeRating}
					name='rating'
					starDimension="20px"
					numberOfStars={5}
					starSpacing="5px"
				/>
				<p>({programData && programData.reviews.length} avis)</p>
				<br/>
				<p>Merci d'avoir noté ce programme !</p>
			</div>
		}
		else{   //S'il a acheté le programme && n'a pas encore noté
			Stars = 
			<div className={classes.note}> 
				<StarRatings
					rating={rating}
					starRatedColor="yellow"
					changeRating={changeRating}
					numberOfStars={5}
					name='rating'
        			starDimension="20px"
        			starSpacing="5px"
				/> <br/>
				<p>Vous n'avez pas encore noté</p>
			</div>
		}
    }else {  //Sinon (si c'est un potentiel client)
		Stars = 
		<div className={classes.note}> 
			<StarRatings
				rating={rating}
				starRatedColor="yellow"
				name='rating'
        		starDimension="20px"
				numberOfStars={5}
        		starSpacing="5px"
			/>
			<p>({programData && programData.reviews.length} avis)</p>
		</div>
	}

	
	let nbVentes;
	if (user && programData && coachData && user.userId === coachData.userId) {   //S'il est le coach qui a créé le programme
		nbVentes =  
			<p>Nombre de ventes : {programData.nbVente}</p>
    }

	return (
			<Layout>
            	<SEO/>
				<div className={classes.ProgramDetailPageComponent}>
					<h2>{programData && programData.name}</h2>
					
					<p>Par : <a onClick={()=>history.push("/coach/" + programData.coachUserId)} style={{cursor : "pointer"}}>{coachData && coachData.fullname}</a></p>

					{Stars}
					{nbVentes}

					<img className={classes.image} src={programData && programData.imageLink}/>

					<p>Niveau : {programData && programData.level} - {programData && programData.nbSeance} séances</p>
					<p>Description :<br/>{programData && programData.description}</p>
					<p>Prix : <b>{programData && programData.price}€</b></p>
					
					{Cta}
				</div>
			</Layout>
	)
}

export default ProgramDetailPage;