import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import {ButtonGroup, Grid, TextField} from "@material-ui/core";
import {ArrowForwardRounded, ImageRounded, TitleRounded} from "@material-ui/icons";
import SimpleMarkdownViewer from "./SimpleMarkdownViewer";
import {useRef, useState} from "react";

export default function SimpleMarkdownEditor({height, onChange, value, noRender = false, tabImages, setTabImages, tabImagesReaded, setTabImagesReaded}){
	
	const inputRef = useRef();
	const fileInputRef = useRef();
	const [selectionStart, setSelectionStart] = useState();
	
	const useStyles = makeStyles((theme) => ({
		root : {
			minHeight : "250px",
			[theme.breakpoints.down('sm')]: {
				minHeight : "500px",
			},
			height : height ? height : "auto"
		},
		rootContainer : {
			width : "100%",
			height : "100%"
		},
		editorRoot : {
			width : "100%",
			[theme.breakpoints.down('sm')]: {
				height : "45%",
			},
			height : "100%"
		},
		arrowRoot : {
			width : "100%",
			height : "100%",
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
			[theme.breakpoints.down('sm')]: {
				height : "10%",
			},
		},
		arrow : {
			width : "25%",
			height : "25%",
			color : theme.palette.secondary.main,
			[theme.breakpoints.down('sm')]: {
				transform : "rotateZ(90deg)",
			},
		},
		editorSpeedDial: {
			position: 'relative',
			bottom: 0,
			right: 0,
		},
		editorContainer : {
			width : "100%",
			height : "100%",
			overflow : "hidden"
		},
		editorTools : {
			height : "50px",
			padding : "8px 0px",
			display : "flex",
			justifyContent : "center"
		},
		editorToolsButtons : {
		
		},
		editorTextFieldContainer : {
			height : "calc(100% - 50px)",
			border : "1px solid " + theme.palette.secondary.main + " !important",
		},
		editorTextField : {
			width : "100%",
			height : "100%",
			"& .MuiInputBase-root" : {
				height : "100%",
				"& .MuiInputBase-input" : {
					height : "100%",
				},
			},
			border : "1px solid " + theme.palette.secondary.main + " !important",
			"& textarea" : {
				color : "white !important",
			}
		},
		viewerContainer : {
			width : "100%",
			height : "100%",
			overflow : "hidden"
		},
		viewerTitle : {
			height : "50px",
			padding : "8px 0px",
			display : "flex",
			justifyContent : "center",
			"& h4" : {
				fontWeight : "bold",
				color : theme.palette.secondary.main,
			}
		},
		viewer : {
			border : "1px solid " + theme.palette.secondary.main,
			borderRadius : "4px",
			height : "calc(100% - 50px)",
			width : "100%"
		},
		viewerRoot : {
			display : noRender ? "none" : "block",
			height : "100%",
			[theme.breakpoints.down('sm')]: {
				height : "45%",
			},
		}
	}));
	const classes = useStyles();
	
	const handleTitle = () => {
		//console.log(value)
		let lignes = value.split("\n")
		let numLigne = 0;
		let cursorPos = 0;
		for(let ligne of lignes){
			if(cursorPos + ligne.length + 1 <= selectionStart){
				numLigne++;
				cursorPos += ligne.length + 1
			}else{
				break
			}
		}
		if(lignes[numLigne].slice(0, 2) === "# "){
			lignes[numLigne] = lignes[numLigne].slice(2)
		}else{
			lignes[numLigne] = "# " + lignes[numLigne]
		}
		onChange(lignes.join("\n"))
	}
	
	const handleImage = () => {
		fileInputRef.current.click()
	}
	
	const updateSelectionStart = () => {
		setSelectionStart(inputRef.current.selectionStart);
	}
	
	const handleFileSelect = (file) => {
		setTabImages([...tabImages, file])
		const reader = new FileReader();
		reader.onload = function(e) {
			setTabImagesReaded([...tabImagesReaded, e.target.result])
			onChange(value.slice(0, selectionStart) + "\n{{" + file.name + ":" + tabImagesReaded.length + "}}\n" + value.slice(selectionStart))
		}
		reader.readAsDataURL(file)
	}
	
	return(
		<Grid item xs={12} className={classes.root}>
			<Grid container className={classes.rootContainer}>
				<Grid item xs={12} md={noRender ? 12 : 5} className={classes.editorRoot}>
					<Grid container className={classes.editorContainer}>
						<Grid item xs={12} className={classes.editorTools}>
							<ButtonGroup variant="contained" color="secondary" aria-label="contained secondary button group" className={classes.editorToolsButtons}>
								<Button onClick={() => handleTitle()}>
									<TitleRounded/> Titre
								</Button>
								<Button onClick={() => handleImage()}>
									<ImageRounded/> Image
									<input
										ref={fileInputRef}
										onChange={(event => {handleFileSelect(event.target.files[0])})}
										accept={"image/*"}
										type="file"
										hidden
									/>
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item xs={12} className={classes.editorTextFieldContainer}>
							<TextField
								onSelect={updateSelectionStart}
								inputRef={inputRef}
								className={classes.editorTextField}
								id="outlined-multiline-static"
								label=""
								multiline
								rows={4}
								value={value}
								variant="outlined"
								onChange={(e) => onChange(e.target.value)}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={noRender ? 0 : 12} md={noRender ? 0 : 2} className={classes.arrowRoot}>
					<ArrowForwardRounded className={classes.arrow}/>
				</Grid>
				<Grid item xs={noRender ? 0 : 12} md={noRender ? 0 : 5} className={classes.viewerRoot}>
					<Grid container className={classes.viewerContainer}>
						<Grid item xs={12} className={classes.viewerTitle}>
							<h4>Rendu</h4>
						</Grid>
						<Grid item xs={12} className={classes.viewer}>
							<SimpleMarkdownViewer value={value} imageFiles={tabImagesReaded}/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
