import React, {useEffect, useState} from "react";
import { useHistory } from "react-router";
import CardPost from "./CardPost";
import {makeStyles} from "@material-ui/core/styles";

const PostsList = ({postsList}) => {
    const history = useHistory();
    
	const useStyles = makeStyles((theme) => ({
        PostListComponent: {  
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-around",
        },
    }));
    const classes = useStyles();
    return (
        <div className={classes.PostListComponent}>
			{
				postsList.length > 0 && postsList.map((post, index) => (
					<CardPost
						onClick={()=>history.push("/post/" + post.blogArticleId)}
						key={index}
						image={post.imageLink}
						title={post.title}
						description={post.description}
					/>
				))
			}
        </div>
    );
};

export default PostsList;
